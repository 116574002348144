import { Api } from "lib/axios/Api";
import { Faq } from "model/types";
import { useEffect, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";

const SettingFaq = () => {
  const [faqs, setFaqs] = useState<Faq[]>([]);

  useEffect(() => {
    Api.get("app/faq").then((response) => {
      setFaqs(response.data);
    });
  }, []);

  return (
    <ListGroup>
      {faqs.map((faq) => (
        <FaqElement key={faq.idx} faq={faq} />
      ))}
    </ListGroup>
  );
};

const FaqElement = ({ faq }: { faq: Faq }) => {
  const [visibled, setVisibled] = useState(false);

  return (
    <ListGroupItem className="dropdown">
      <Row className="flex-column m-0 text-dark-slate-blue p-1">
        <Col className="p-0">
          <Row>
            <Col>{faq.title}</Col>
            <Col className="col-auto" onClick={() => setVisibled(() => !visibled)}>
              {visibled ? "∧" : "∨"}
            </Col>
          </Row>
        </Col>
        <Col className={`p-0 fs-7 fw-r content ${visibled ? "mt-3" : "hidden"}`}>
          <div dangerouslySetInnerHTML={{ __html: faq.text }}></div>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default SettingFaq;
