import { useRef } from "react";
import "./MyToast.scss";

interface MessageInterface {
  text: string;
  option?: {
    deley: number;
  };
}

let _setMessage: Function | null;

const MyToast = {
  success: (message: MessageInterface) => {
    if (_setMessage) _setMessage("success", message);
  },
};

let animation: Animation;
export const MyToastContainer = () => {
  const textWrapRef = useRef<HTMLDivElement>(null);

  const messages = {
    success: [] as MessageInterface[],
  };

  _setMessage = (type: string, message: MessageInterface) => {
    if (type === "success") {
      messages.success.push(message);
      updateMessages();
    }
  };

  const updateMessages = () => {
    const message = messages.success.shift();
    if (!textWrapRef.current || !message) return;

    textWrapRef.current.innerHTML = message.text;
    if (animation) animation.cancel();
    textWrapRef.current.style.opacity = "0.7";
    animation = textWrapRef.current.animate([{ opacity: 0 }], {
      duration: 500,
      easing: "ease-out",
      iterations: 1,
      fill: "both",
      delay: 2500,
    });
  };

  return (
    <div id="my_toast_container">
      <div ref={textWrapRef}></div>
    </div>
  );
};

export default MyToast;
