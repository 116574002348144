import { Api } from "lib/axios/Api";
import { BottomSheetItem } from "model/types";
import { getMapCenter } from "../NaverMap";

export interface searchParams {
  idxs?: number[]; // 인덱스
  ccbaCtcdNm?: string; // 시/도
  ccsiName?: string; // 시/군/구
  ccceName?: string; // 시대
  typeIdx?: number; // 문제 유형
  lat: number; // 위도
  lng: number; // 경도
  pageNum?: number; // 페이지 번호
  pageSize?: number; // 페이지 크기
  orderBy: number; // 정렬 순서 0: 거리순, 1: 기출순
}

interface BottomSheetSearchInterface {
  lastParam: Partial<searchParams>;
  items: (params: searchParams) => Promise<BottomSheetItem[]>;
  more: () => Promise<BottomSheetItem[]>;
  reSearch: () => Promise<BottomSheetItem[]>;
  orderBy: (orderBy: number) => Promise<BottomSheetItem[]>;
  totalCnt: () => Promise<number>;
}

const BottomSheetSearch: BottomSheetSearchInterface = {
  lastParam: {},
  items: async (params: searchParams) => {
    BottomSheetSearch.lastParam = params;
    const response = await Api.post("/app/bottom_sheet", params);
    return response.data;
  },

  more: async () => {
    return await BottomSheetSearch.items({
      ...BottomSheetSearch.lastParam,
      pageNum: BottomSheetSearch.lastParam.pageNum ? BottomSheetSearch.lastParam.pageNum + 1 : 1,
    } as searchParams);
  },

  reSearch: async () => {
    return await BottomSheetSearch.items({
      ...BottomSheetSearch.lastParam,
      ...getMapCenter(),
      pageNum: 0,
    } as searchParams);
  },

  orderBy: async (orderBy: number) => {
    return await BottomSheetSearch.items({
      ...BottomSheetSearch.lastParam,
      orderBy,
      pageNum: 0,
    } as searchParams);
  },

  totalCnt: async () => {
    const response = await Api.post("/app/bottom_sheet/total_count", BottomSheetSearch.lastParam);
    return response.data;
  },
};

export default BottomSheetSearch;
