import { appErrorMsg, appSuccessMsg, isUserLogin, nickNameCheck } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { User } from "model/User";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MyModify = () => {
  const [user, setUser] = useState<User>();
  const [param, setParam] = useState({ gender: 0, nickName: "" });
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLogin())
      Api.get("app/auth/my")
        .then((response) => {
          setUser(response.data);
          setParam({ gender: response.data.gender, nickName: response.data.nickName });
        })
        .catch(() => {
          appErrorMsg({ msg: "서버에 접속할 수 없습니다." });
          navigate("/");
        });
  }, [navigate]);

  const checkChange = () => {
    return user?.nickName !== param.nickName || user.gender !== param.gender;
  };

  const updateClickHandler = async () => {
    if (!checkChange()) {
      appErrorMsg({ msg: "변경사항이 없습니다." });
      return false;
    } else if (user?.nickName !== param.nickName && !(await nickNameCheck(param.nickName))) {
      return false;
    }

    Api.put("app/auth/update", { ...param }).then((response) => {
      appSuccessMsg({ msg: "변경되었습니다." });
      navigate(-1);
    });
  };

  if (user)
    return (
      <AppModal title="내정보 변경">
        <Row className="flex-column h-100 m-0 p-3">
          <Col xs="auto" className="mt-3">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>닉네임</Form.Label>
              <Form.Control
                type="text"
                placeholder={user?.nickName}
                defaultValue={param.nickName}
                onKeyUp={(e) => setParam({ ...param, nickName: e.target.value })}
                onBlur={(e) => {
                  if (e.target.value.trim() === "") {
                    e.target.value = user.nickName;
                    setParam({ ...param, nickName: user.nickName });
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col className="mt-3">
            <Form.Label>성별</Form.Label>
            <InputGroup as={Col} className="flex-fill w-auto">
              <Form.Check
                type="radio"
                name="gender"
                defaultChecked={user?.gender === 0}
                onChange={() => setParam({ ...param, gender: 0 })}
                required
                color="dark-slate-blue"
                className="me-3"
              />
              <Form.Label>비공개</Form.Label>
            </InputGroup>
            <InputGroup as={Col} className="flex-fill w-auto">
              <Form.Check
                type="radio"
                name="gender"
                defaultChecked={user?.gender === 1}
                onChange={() => setParam({ ...param, gender: 1 })}
                required
                color="dark-slate-blue"
                className="me-3"
              />
              <Form.Label>남자</Form.Label>
            </InputGroup>
            <InputGroup as={Col} className="flex-fill w-auto">
              <Form.Check
                type="radio"
                name="gender"
                defaultChecked={user?.gender !== 1}
                onChange={() => setParam({ ...param, gender: 2 })}
                required
                color="dark-slate-blue"
                className="me-3"
              />
              <Form.Label>여자</Form.Label>
            </InputGroup>
          </Col>
          <Col xs="auto">
            <Button className="w-100" variant="dark-slate-blue" disabled={!checkChange()} onClick={updateClickHandler}>
              변경 완료
            </Button>
          </Col>
        </Row>
      </AppModal>
    );
  return <></>;
};

export default MyModify;
