import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

export let getModalDeclarationData: () => [string];

const ModalDeclaration = () => {
  const [text, setText] = useState("");

  getModalDeclarationData = () => [text];

  return (
    <Row className="flex-column p-3 m-0">
      <Col className="fs-5 mb-3">이의 신청</Col>
      <Col className="mb-3">
        <Form.Control
          as="textarea"
          className="noresize fw-r fs-7 rounded-0"
          rows={7}
          placeholder="내용을 기재해주세요."
          maxLength={400}
          onChange={(e) => setText(e.target.value)}
        />
      </Col>
      <Col className="fw-r fs-7 bg-gray p-2 text-brown-grey">
        <div className="mb-2">1. 이의신청은 1개의 게시물에 1번만 가능합니다.</div>
        <div>
          2. 이의신청 시 관리자의 판단 하에 이의신청 인정/반려 처리 되며 인정 처리시 해당 게시물의 적용된 제지 사항이
          취소되며, 반려 처리시 해당 게시물의 적용된 제지 사항이 유지됩니다. (반려 처리의 경우 따로 사유를 안내하지
          않으며, 해당 사항에 대한 문의는(설정 &gt; 1:1문의 &gt; 문의하기)를 통해 진행하시기 바랍니다.
        </div>
      </Col>
    </Row>
  );
};

export default ModalDeclaration;
