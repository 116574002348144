import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import store from "store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import User from "User";
import ReactGA from "react-ga";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
document.documentElement.lang = "ko";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string;
ReactGA.initialize(TRACKING_ID);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <User />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
