import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReviewByBlind = () => {
  return (
    <Row className="py-3 m-0 border-bottom">
      <Col className="text-brown-grey fw-r fs-7 pt-1">사용자에 의해 차단된 리뷰입니다.</Col>
      <Col className="col-auto px-1">
        <Link to="/setting/blind_list">
          <Button
            className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
            variant="outline-brown-grey"
          >
            차단관리로 이동
          </Button>
        </Link>
      </Col>
    </Row>
  );
};

export default ReviewByBlind;
