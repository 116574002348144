import { ImageGroup } from "components/ImageGroup/ImageGroup";
import { Api } from "lib/axios/Api";
import { CulturalAsset } from "model/CulturalAsset";
import { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";

interface Props {
  cul: CulturalAsset;
}
const DetailPhoto = ({ cul }: Props) => {
  const [culImageUrls, setCulImageUrls] = useState<string[]>([]);

  useEffect(() => {
    Api.get(`/app/cultural_asset_images/search/${cul.idx}`).then((response) => {
      let imageUrls;
      if (response.data) imageUrls = response.data.map((url: string) => convertImageUrl(url));
      else imageUrls = response.data;
      setCulImageUrls(imageUrls);
    });
  }, [cul.idx]);

  const imageClickHandler = (culImageUrl: string) => {
    ImageGroup.setImages(culImageUrls);
    ImageGroup.setIndex(culImageUrls.indexOf(culImageUrl));
  };

  const convertImageUrl = (url: string) => {
    if (url.indexOf("http") !== 0) return process.env.REACT_APP_IMAGE_URL + url;
    else return url.replace("http://", "https://");
  };

  return (
    <Row xs={2} className="m-0 p-0">
      <Col className="m-0 p-0">
        <Row xs={1} className="m-0 p-1">
          {culImageUrls
            .filter((v, i) => i % 2 === 0)
            .map((culImageUrl, i) => (
              <Col key={i} className="m-0 p-1">
                <Image src={culImageUrl} className="w-100" onClick={() => imageClickHandler(culImageUrl)} />
                {culImageUrl.indexOf("www.cha.go.kr") >= 0 && (
                  <div className="text-end text-secondary fs-7">이미지 출처 : 문화재 청</div>
                )}
              </Col>
            ))}
        </Row>
      </Col>
      <Col className="m-0 p-0">
        <Row xs={1} className="m-0 p-1">
          {culImageUrls
            .filter((v, i) => i % 2 === 1)
            .map((culImageUrl) => (
              <Col key={culImageUrl} className="m-0 p-1">
                <Image src={culImageUrl} className="w-100" onClick={() => imageClickHandler(culImageUrl)} />
                {culImageUrl.indexOf("www.cha.go.kr") >= 0 && (
                  <div className="text-end text-secondary fs-7">이미지 출처 : 문화재 청</div>
                )}
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default DetailPhoto;
