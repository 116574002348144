import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Inquiry } from "model/types";
import { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const InquiryList = () => {
  const navigate = useNavigate();
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);

  useEffect(() => {
    Api.get("app/inquiry").then((response) => {
      setInquiries(response.data);
    });
  }, []);

  return (
    <AppModal title="1:1 문의" backClickHandler={() => navigate(-1)}>
      <Row className="flex-column m-0 h-100">
        <Col className="p-2 ps-4 pe-3 col-auto fs-7 border-bottom mt-2">
          <Row className="m-0">
            <Col className="p-0 pt-2" xs="auto">
              <Form.Check
                inline
                name="search_type"
                label="전체"
                type="radio"
                defaultChecked={true}
                // onChange={() => searchChange(0)}
                color="dark-slate-blue"
              />
            </Col>
            <Col className="p-0 pt-2" xs="auto">
              <Form.Check
                inline
                name="search_type"
                label="답변완료"
                type="radio"
                // onChange={() => searchChange(1)}
                color="dark-slate-blue"
              />
            </Col>
            <Col className="p-0 pt-2" xs="auto">
              <Form.Check
                inline
                name="search_type"
                label="답변대기중"
                type="radio"
                // onChange={() => searchChange(1)}
                color="dark-slate-blue"
              />
            </Col>
            <Col className="p-0 ms-auto" xs="auto">
              <Link to="create">
                <Button variant="dark-slate-blue">문의하기</Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col className="p-0">
          <ListGroup>
            {inquiries.map((inquiry) => (
              <InquiryElement inquiry={inquiry} key={inquiry.idx} />
            ))}
          </ListGroup>
        </Col>
      </Row>
    </AppModal>
  );
};

interface InquiryElementProps {
  inquiry: Inquiry;
}
const InquiryElement = ({ inquiry }: InquiryElementProps) => {
  return (
    <ListGroupItem className="dropdown" style={{ borderWidth: "0 0 1px 0" }}>
      <Row className="flex-column">
        <Col>
          <Row>
            <Col>
              [{inquiry.type}] {inquiry.title}
            </Col>
            <Col xs="auto">
              <Link to={`/setting/inquiry/${inquiry.idx}`}>
                <Button className="btn-sm" variant="outline-primary">
                  내용 보기
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col>
          {inquiry.status === 0 ? <span className="text-danger">답변 대기</span> : "답변 완료"} | {inquiry.createdAt}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default InquiryList;
