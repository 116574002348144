import { Col, Image, Row } from "react-bootstrap";
import { KakaoLoginButton } from "./LoginButtons/KakaoLogin";
import { NaverLoginButton } from "./LoginButtons/NaverLogin";
import { AppleLoginButton } from "./LoginButtons/AppleLogin";
import "styles/app_login.scss";
import { isIos } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import playHistoryMarker from "assets/images/icon/play_history_marker.png";

const AppLogin = () => {
  localStorage.removeItem("delete_auth");
  return (
    <AppModal>
      <Row className="flex-column flex-nowrap w-100 h-100 m-0 text-center" xs={2}>
        <Col className="mt-auto text-center w-100" xs={6}>
          <Row className="h-100">
            <Col className="align-self-center">
              <Row className="h-100 flex-column">
                <Col className="align-self-center text-center">
                  <Image src={playHistoryMarker} />
                </Col>
                <Col xs="auto" className="fs-6 text-dark-slate-blue">
                  한국사를 한눈에
                </Col>
                <Col xs="auto" className="fs-1 text-dark-slate-blue">
                  역지사지
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col className="mt-auto bd-highlight w-100 p-0 mb-4" xs={6}>
          <div className="p-4 flex-column">
            <Row className="login-btn-wrap p-4">
              <AppleLoginButton />
              <KakaoLoginButton />
              <NaverLoginButton />
            </Row>
          </div>
        </Col>
      </Row>
    </AppModal>
  );
};

export default AppLogin;
