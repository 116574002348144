import { Api } from "lib/axios/Api";
import { TermDetail } from "model/types";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const SignTerm = () => {
  const [termDetail, setTermDetail] = useState<TermDetail | null>(null);
  const { termIdx } = useParams() as { termIdx: string };
  const navigate = useNavigate();

  useEffect(() => {
    Api.get(`/app/term/${termIdx}`).then((response) => {
      setTermDetail(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termIdx]);

  return (
    <div
      id="app_modal_wrap"
      className="d-flex flex-column"
      style={{ position: "absolute", left: 0, top: 0, backgroundColor: "#FFF" }}
    >
      <Row className="m-1">
        <span className="arrow-prev ms-2" onClick={(e) => navigate(-1)}></span>
        <Col className="w-100 text-center mt-2">{termDetail?.term.title}</Col>
      </Row>
      <hr className="my-2" />
      <div className="h-100 w-100 m-0" style={{ overflowX: "hidden" }}>
        <div className="p-3" dangerouslySetInnerHTML={{ __html: termDetail?.column.text ?? "" }} />
      </div>
    </div>
  );
};

export default SignTerm;
