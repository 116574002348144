import AppleLoginBtnImage from "assets/images/sns/apple_login_btn.png";
import { appSuccessMsg } from "assets/js/common";
import { userLogin } from "features/appSlice";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export const AppleLoginButton = ({ deleted = false }: { deleted?: boolean }) => {
  if (deleted) {
    localStorage.setItem(
      "delete_auth",
      JSON.stringify({
        date: new Date(),
      })
    );
  }

  const appleLogin = async () => {
    window.AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID as string,
      scope: "email",
      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI as string,
      state: "STATE",
      nonce: "NONCE",
    });

    try {
      const res = await window.AppleID.auth.signIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ backgroundColor: "#000" }} onClick={appleLogin}>
      <Image src={AppleLoginBtnImage} className="" />
      <span className="text-white">Apple로 로그인</span>
    </div>
  );
};

const AppleLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (searchParams.get("accessToken") && searchParams.get("refreshToken")) {
    const delete_auth = localStorage.getItem("delete_auth");
    localStorage.removeItem("delete_auth");
    appSuccessMsg({ msg: "로그인되었습니다." });
    const userToken = {
      accessToken: searchParams.get("accessToken") as string,
      refreshToken: searchParams.get("refreshToken") as string,
    };
    dispatch(userLogin({ userToken }));

    // 탈퇴 페이지
    if (delete_auth) {
      navigate("/sign/delete");

      // 로그인 성공
    } else {
      navigate("/");
    }
  } else {
    const params: any = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    navigate("/sign", { state: params });
  }

  return <div id="app_modal_wrap" className="d-flex flex-column" style={{ right: `0px` }}></div>;
};

export default AppleLogin;
