import { appSuccessMsg } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const DeleteAccount = () => {
  const navigate = useNavigate();

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    Swal.fire({
      title: "탈퇴하시겠습니까?",
      showDenyButton: true,
      confirmButtonText: "탈퇴",
      denyButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete("/app/auth").then(() => {
          appSuccessMsg({ msg: "탈퇴되었습니다." });
          navigate("/");
        });
      }
    });
  };
  return (
    <AppModal title="서비스 탈퇴하기">
      <Form className="h-100" onSubmit={submitHandler}>
        <Row className="flex-column h-100">
          <Col>
            <Row className="flex-column justify-content-center h-100">
              <Col className="align-self-center col-auto">
                <Row className="flex-column p-2 m-0">
                  <Col className="text-danger mb-2">
                    회원 탈퇴 시 이용 내역 등 모든 정보가 삭제되어 복구가 불가능합니다.
                  </Col>
                  <Col className="border p-4 mb-3">
                    탈퇴 시 주의사항 ※<br />
                    - 테스트 탈퇴 주의사항 테스트 123
                    <br />
                    - 테스트 탈퇴 주의사항 테스트 123
                    <br />
                    - 테스트 탈퇴 주의사항 테스트 123
                    <br />- 테스트 탈퇴 주의사항 테스트 123
                  </Col>
                  <Col>
                    <Form.Check
                      inline
                      label="[필수]"
                      name="status"
                      type="checkbox"
                      id="status_0"
                      className="text-primary"
                      required
                    />
                    <Form.Label>주의 사항을 모두 확인했습니다.</Form.Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-auto">
            <Button className="w-100" type="submit">
              탈퇴하기
            </Button>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default DeleteAccount;
