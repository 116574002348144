import { isIos } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import BottomSheetContext from "shared/contexts/BottomSheetContext";

const SettingPermission = () => {
  const { geoPermission } = useContext(BottomSheetContext);

  useEffect(() => {
    let isStop = false;
    const checkPermission = () => {
      if (isIos()) {
        window.webkit.messageHandlers.GeoPermissionCheck &&
          window.webkit.messageHandlers.GeoPermissionCheck.postMessage("");
      } else {
        window.JavaScript.GeoPermissionCheck && window.JavaScript.GeoPermissionCheck();
      }
      setTimeout(() => {
        !isStop && checkPermission();
      }, 300);
    };

    checkPermission();
    return () => {
      isStop = true;
    };
  }, []);

  return (
    <AppModal title="권한">
      <PermissionElement title="내 위치 정보 허용" permission={geoPermission} link="" />
    </AppModal>
  );
};

interface PermissionElementProps {
  title: string;
  permission: boolean;
  link: string;
}

const PermissionElement = (props: PermissionElementProps) => {
  const getGeoPermission = () => {
    if (isIos()) {
      if (window.webkit) window.webkit.messageHandlers.GeoPermissionRequest.postMessage("");
    } else {
      if (window.JavaScript.GeoPermissionRequest) window.JavaScript.GeoPermissionRequest();
    }
  };

  return (
    <Row className="p-3 border">
      <Col className="pt-1">{props.title}</Col>
      <Col className="col-auto">
        <Row className="flex-column text-end">
          <Col className="fs-7">{props.permission ? "허용" : "허용안함"}</Col>
          {!props.permission && (
            <Col className="fs-8 text-primary" onClick={getGeoPermission}>
              권한 설정으로 이동
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default SettingPermission;
