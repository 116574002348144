import AppModal from "components/layouts/AppModal";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "styles/app_setting_service.scss";
import SettingNotice from "./SettingNotice";
import SettingFaq from "./SettingFaq";
import ServiceIcon from "assets/images/icon/icon_service.png";

const Service = (props: any) => {
  const [type, setType] = useState("notice");
  const [children, setChildren] = useState<JSX.Element>(<SettingNotice />);

  useEffect(() => {
    switch (type) {
      case "notice":
        setChildren(<SettingNotice />);
        break;

      default:
        setChildren(<SettingFaq />);
        break;
    }
  }, [type]);

  return (
    <AppModal title="고객센터">
      <Row className="flex-column">
        <Col className="text-center p-3">
          <img src={ServiceIcon} alt="" width={120} />
        </Col>
        <Col>
          <Row className="m-0 text-center" id="service_menu">
            <Col onClick={() => setType("notice")} className={type === "notice" ? "active" : ""}>
              공지사항
            </Col>
            <Col onClick={() => setType("faq")} className={type === "notice" ? "" : "active"}>
              자주 묻는 질문
            </Col>
          </Row>
        </Col>
        <Col>{children}</Col>
      </Row>
    </AppModal>
  );
};

export default Service;
