import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MyContent from "./MyContent";
import "styles/app_setting_wrap.scss";
import { Term } from "model/types";
import { Api } from "lib/axios/Api";
import AppModal from "components/layouts/AppModal";

const SERVICES_1 = [
  { text: "1:1 문의", link: "setting/inquiry" },
  { text: "고객 센터", link: "setting/service" },
  { text: "권한 설정", link: "setting/permission" },
];

const AppSetting = () => {
  const [terms, setTerms] = useState<Term[]>([]);

  useEffect(() => {
    Api.get("app/term").then((response) => setTerms(response.data));
  }, []);

  const ServiceContent = () => {
    return (
      <>
        <div className="py-2">
          {SERVICES_1.map((service, i) => (
            <LinkButton {...service} key={i} />
          ))}
        </div>

        <div className="py-2">
          {terms.map((term, i) => (
            <LinkButton text={term.title} link={`setting/term/${term.idx}`} key={i} />
          ))}
        </div>
      </>
    );
  };
  return (
    <AppModal title="설정">
      <Row className="flex-column m-0">
        <Col className="px-2">
          <MyContent isMyPageBtn={true} />
        </Col>
        <Col>
          <ServiceContent />
        </Col>
      </Row>
    </AppModal>
  );
};

const LinkButton = ({ text, link }: any) => {
  return (
    <div className="m-2">
      <Link to={`/${link}`}>
        <Button variant="outline-very-light-pink rounded-0" className="w-100 p-2 text-dark fs-7">
          {text}
        </Button>
      </Link>
    </div>
  );
};

export default AppSetting;
