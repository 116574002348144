import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Term, TermColumn } from "model/types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SettingTerm = () => {
  const params = useParams();
  const { idx } = params;
  const [term, setTerm] = useState<Term | null>(null);
  const [column, setColumn] = useState<TermColumn | null>(null);

  useEffect(() => {
    Api.get(`/app/term/${idx}`).then((response) => {
      setTerm(response.data.term);
      setColumn(response.data.column);
    });
  }, [idx]);

  return (
    <AppModal title={term?.title ?? ""}>
      <div className="p-3 fw-r" dangerouslySetInnerHTML={{ __html: column?.text ?? "" }} />
    </AppModal>
  );
};

export default SettingTerm;
