import { useState } from "react";
import "./MyConfirm.scss";
import { Col, Row } from "react-bootstrap";

let _showMyConfirm: Function | undefined;

interface Props {
  showCancelBtn?: boolean;
  okBtnText?: string;
  text: string | JSX.Element;
  onCancelClick?: Function;
  onOkClick?: Function;
}

const MyConfirm = (props: Props) => {
  props = { showCancelBtn: true, okBtnText: "확인", ...props };
  if (_showMyConfirm) _showMyConfirm(props);
};

export const MyComfirmContainer = () => {
  const [props, setProps] = useState<Props | null>(null);

  _showMyConfirm = setProps;

  const convertText = () => {
    if (props) {
      if (typeof props.text === "string") return <div id="my_confirm_text">{props.text}</div>;
      else return props.text;
    }
    return <></>;
  };

  const onCancelClick = () => {
    if (props && props.onCancelClick) {
      if (props.onCancelClick() === false) return;
    }
    setProps(null);
  };

  const onOkClick = () => {
    if (props && props.onOkClick) {
      if (props.onOkClick() === false) return;
    }
    setProps(null);
  };

  return (
    <div id={`my_confirm_container`} className={props ? "actived" : ""}>
      {props && (
        <Row className="flex-column">
          <Col className="p-0">{convertText()}</Col>
          <Col className="p-0">
            <Row id="my_confirm_btns">
              {props.showCancelBtn && <Col onClick={onCancelClick}>취소</Col>}
              <Col onClick={onOkClick}>{props.okBtnText}</Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MyConfirm;
