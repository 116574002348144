import ReviewByNomal from "components/DetailReviews/ReviewByNomal";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Review } from "model/Review";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const MyReview = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = () => {
    const lastIdx = reviews.length > 0 ? reviews[reviews.length - 1].idx : null;
    Api.get("app/reviews/my", { params: { lastIdx } }).then((response) => {
      setReviews([...reviews, ...response.data]);
    });
    Api.get("app/reviews/count/my").then((response) => setCount(response.data));
  };

  return (
    <AppModal title={`내가 쓴 리뷰 글(${count})`}>
      <Row>
        <Col className="h-100 overflow-auto border-top mt-3">
          <Row className="w-100 m-0">
            {reviews.map((review, i) => (
              <ReviewByNomal review={review} setReviews={setReviews} setCount={setCount} key={i} />
            ))}
          </Row>
        </Col>
      </Row>
    </AppModal>
  );
};

export default MyReview;
