import { appErrorMsg, commonSave } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Inquiry, InquiryColumn, InquiryType } from "model/types";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormGroup, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const InquiryCreate = () => {
  const [inquiry, setInquiry] = useState<Partial<Inquiry>>({});
  const [inquiryColumn, setInquiryColumn] = useState<Partial<InquiryColumn>>({});
  const [inquiryTypes, setInquiryTypes] = useState<InquiryType[]>([]);
  const inputFile = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    Api.get("app/inquiry_types").then((response) => setInquiryTypes(response.data));
  }, []);

  const updateModel = (fieldsToUpdate: Partial<Inquiry>) => {
    if (inquiry) setInquiry({ ...inquiry, ...fieldsToUpdate });
  };

  const imgChangeHandler = (e: any) => {
    const formData = new FormData();
    if (e.target.files[0].size > 2097152) {
      appErrorMsg({ msg: "이미지는 2MB를 넘을 수 없습니다." });
      return false;
    }
    formData.append("upload", e.target.files[0]);
    formData.append("uri", "/inquiry");
    Api.post(`/app/images`, formData).then((response) => {
      updateModel({ imageUrl: response.data.url });
    });
  };

  const submitHanlder = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    commonSave({ send });
    return false;
  };

  const send = () => {
    if (!inquiryColumn.text) return;
    const column: InquiryColumn = inquiryColumn as InquiryColumn;
    const params: Inquiry = {
      ...(inquiry as Inquiry),
      inquiryColumns: [column],
    };
    return Api.post("app/inquiry", { ...params }).then((response) => {
      if (response.status === 200) navigate(-1);
    });
  };

  return (
    <AppModal title="문의작성" backClickHandler={() => navigate(-1)}>
      <Form className="h-100" onSubmit={submitHanlder}>
        <Row className="flex-column m-0 h-100">
          <Col className="p-3 col-auto">
            <FormGroup>
              <Form.Select required onChange={(e) => updateModel({ type: e.target.value })}>
                <option value="">문의 유형</option>
                {inquiryTypes.map((inquiryType) => (
                  <option value={inquiryType.title} key={inquiryType.idx}>
                    {inquiryType.title}
                  </option>
                ))}
              </Form.Select>
            </FormGroup>
          </Col>
          <Col className="px-3 mb-3 col-auto">
            <FormGroup>
              <Form.Control
                placeholder="문의 제목을 입력해 주세요."
                maxLength={40}
                defaultValue={inquiry.title ?? ""}
                onChange={(e) => updateModel({ title: e.target.value })}
                required
              />
            </FormGroup>
          </Col>
          <Col className="px-3 h-100 mb-2">
            <FormGroup className="h-100">
              <Form.Control
                as="textarea"
                className="noresize h-100"
                onChange={(e) =>
                  setInquiryColumn((column) => {
                    return { text: e.target.value, column };
                  })
                }
                required
                maxLength={400}
              />
            </FormGroup>
          </Col>
          <Col className="pe-3 text-end text-very-light-pink fw-r mb-3" xs="auto">
            {inquiryColumn.text?.length || 0} / 400
          </Col>
          <Col className="col-auto border-top border-bottom py-3">
            <input
              type="file"
              ref={inputFile}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={(e) => imgChangeHandler(e)}
            />
            <Button variant="outline-primary" onClick={(e) => inputFile.current?.click()}>
              이미지 첨부
            </Button>
            <Image src={inquiry.imageUrl} style={{ maxHeight: "10rem" }} />
          </Col>
          <Col className="p-3 col-auto">
            <Row>
              <Col>
                <Button className="w-100 p-3 fs-5" variant="dark-slate-blue" type="submit">
                  작성하기
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default InquiryCreate;
