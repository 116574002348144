import { BottomSheetItem } from "../../model/types";
import { createContext } from "react";

interface ContextProps {
  bottomSheetItems: BottomSheetItem[];
  setBottomSheetItems: any;
  isOrder: boolean;
  setIsOrder: any;
  orderBy: number;
  setOrderBy: any;
  geoPermission: boolean;
  setGeoPermission: any;
}

const BottomSheetContext = createContext<ContextProps>({
  bottomSheetItems: [],
  setBottomSheetItems: (params: any) => {},
  isOrder: true,
  setIsOrder: (params: any) => {},
  orderBy: 0,
  setOrderBy: (params: any) => {},
  geoPermission: false,
  setGeoPermission: (bool: boolean) => {},
});

export default BottomSheetContext;
