import { Api } from "lib/axios/Api";

interface BoundsFilter {
  minLat: number;
  minLng: number;
  maxLat: number;
  maxLng: number;
}

class AppSearch {
  public level = {
    sido: (params?: any) => {
      return this.searchBase({ path: "sido", params });
    },
    sigungu: (params: BoundsFilter | any) => {
      return this.searchBase({ path: "sigungu", params });
    },
    cul: (params: BoundsFilter | any) => {
      return this.searchBase({ path: "cul", params });
    },
  };

  public region = {
    sigungu: (sido: String) => {
      return this.searchBase({ params: { ccbaCtcdNm: sido }, path: "sigungu_list" });
    },
    search: (params: any) => {
      return this.searchBase({ params, path: "search_region" });
    },
  };

  public age = {
    search: (params: any) => {
      return this.searchBase({ params, path: "age" });
    },
  };

  public question = {
    search: (params: any) => {
      return this.searchBase({ params, path: "question_type" });
    },
  };

  public search = {
    name: async (name: string, pageNum: number) => {
      const params = { ccbaMnm1: name, pageNum };
      return this.searchBase({ params, path: "search" });
    },
  };

  public detail = {
    idx: (idx: string) => {
      return this.searchBase({ path: `find/${idx}` });
    },
  };

  public favorite = {
    around: async (params: any) => {
      const response = await Api.get(`/app/favorites/around`, { params });
      return response.data;
    },
  };

  private searchBase = async ({ path, params }: any) => {
    const response = await Api.get(`/app/cultural_assets/${path}`, { params });
    return response.data;
  };
}

export default new AppSearch();
