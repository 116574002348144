import { Button, ButtonGroup, Col, Form, Image, Row } from "react-bootstrap";
import "styles/app_header.scss";
import RegionFilter from "./filters/RegionFilter";
import { NaverMapObject } from "./NaverMap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { APP_MODES, changeAppMode } from "features/appSlice";
import AgeFilter from "./filters/AgeFilter";
import { FilterResult } from "./filters/FilterResult";
import { Link, Route, Routes } from "react-router-dom";
import QuestionFilter from "./filters/QuestionFilter";
import IconSettiong from "assets/images/icon/icon_setting.png";
import IconMyCoord from "assets/images/icon/icon_my_coord.png";
import IconRegion from "assets/images/icon/icon_region.png";
import IconRegionActived from "assets/images/icon/icon_region_actived.png";
import IconAge from "assets/images/icon/icon_age.png";
import IconAgeActived from "assets/images/icon/icon_age_actived.png";
import IconQuestion from "assets/images/icon/icon_question.png";
import IconQuestionActived from "assets/images/icon/icon_question_actived.png";
import IconPlus from "assets/images/icon/icon_plus.png";
import IconMinus from "assets/images/icon/icon_minus.png";
import { useContext } from "react";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import { appErrorMsg } from "assets/js/common";

const AppHeader = () => {
  return (
    <Routes>
      <Route index element={<AppHeaderWrap />} />
      <Route path="search_result" element={<FilterResultWrap />} />
    </Routes>
  );
};

const AppHeaderWrap = () => {
  const { geoPermission } = useContext(BottomSheetContext);
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  const changeSelect = (thisMode: number) => {
    if (appState.mode === thisMode) dispatch(changeAppMode({ mode: APP_MODES.DEFULAT }));
    else dispatch(changeAppMode({ mode: thisMode }));
  };

  const Filter = ({ name, mode, icon, activedIcon }: any) => {
    return (
      <Col className="p-2 filter-item" xs="auto">
        <Button
          className="w-100 rounded-pill"
          variant={mode === appState.mode ? "rouge" : "white"}
          onClick={() => changeSelect(mode)}
        >
          <Image src={mode === appState.mode ? activedIcon : icon} width={20} className="me-1 pb-1" />
          {name}
        </Button>
      </Col>
    );
  };

  return (
    <div id="app_header">
      <Row className="flex-column">
        <Col>
          <Row className="p-3 pb-1 pt-5">
            <Form.Group as={Col} className="pe-1">
              <Link to="/search" className="text-decoration-none search">
                <Form.Control placeholder="문화재 명 검색" />
              </Link>
            </Form.Group>
            <Col xs="auto" className="ps-1">
              <Link to="/setting">
                <Button id="btn_setting" variant="white">
                  <Image src={IconSettiong} style={{ height: "23.5px" }} />
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="px-3 pb-1" id="filter_wrap">
            <Filter name="지역별" mode={APP_MODES.FILTER_REGION} icon={IconRegion} activedIcon={IconRegionActived} />
            <Filter name="시대별" mode={APP_MODES.FILTER_AGE} icon={IconAge} activedIcon={IconAgeActived} />
            <Filter
              name="기출별"
              mode={APP_MODES.FILTER_QUESTION}
              icon={IconQuestion}
              activedIcon={IconQuestionActived}
            />
          </Row>
        </Col>
        <Col className="col-auto">
          <Row className="pb-2">
            <Col className="d-flex flex-row-reverse me-3">
              <Image src={IconMyCoord} id="btn_coord" onClick={() => moveByUserCenter(geoPermission)} width={20} />
            </Col>
          </Row>
          <Row id="zoom_wrap">
            <Col className="d-flex flex-column flex-row-reverse me-3">
              <ButtonGroup vertical className="p-0 m-0">
                <Button variant="white" onClick={() => NaverMapObject.zoomIncrease()}>
                  <Image src={IconPlus} width={20} />
                </Button>
                <Button variant="white" onClick={() => NaverMapObject.zoomDecrease()}>
                  <Image src={IconMinus} width={20} />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      {appState.mode === APP_MODES.FILTER_REGION && <RegionFilter />}
      {appState.mode === APP_MODES.FILTER_AGE && <AgeFilter />}
      {appState.mode === APP_MODES.FILTER_QUESTION && <QuestionFilter />}
    </div>
  );
};

const FilterResultWrap = () => {
  const { geoPermission } = useContext(BottomSheetContext);
  return (
    <div id="app_header">
      <FilterResult />
      <Row className="flex-column">
        <Col className="col-auto mt-3">
          <Row className="pb-2">
            <Col className="d-flex flex-row-reverse me-2">
              <Image src={IconMyCoord} id="btn_coord" onClick={() => moveByUserCenter(geoPermission)} width={20} />
            </Col>
          </Row>
          <Row id="zoom_wrap">
            <Col className="d-flex flex-column flex-row-reverse me-2">
              <ButtonGroup vertical className="p-0 m-0">
                <Button variant="white" onClick={() => NaverMapObject.zoomIncrease()}>
                  <Image src={IconPlus} width={20} />
                </Button>
                <Button variant="white" onClick={() => NaverMapObject.zoomDecrease()}>
                  <Image src={IconMinus} width={20} />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const moveByUserCenter = (geoPermission: boolean) => {
  if (!geoPermission) {
    appErrorMsg({ msg: "위치 권한이 없습니다." });
  } else {
    NaverMapObject.moveByUserCenter();
  }
};

export default AppHeader;
