import { appSuccessMsg, getUserNickName } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { BlindUser } from "model/Review";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const SettingBlindList = () => {
  const navigate = useNavigate();
  const [blinds, setBlinds] = useState<BlindUser[]>([]);

  useEffect(() => {
    Api.get("app/blind_user").then((response) => {
      setBlinds(response.data);
    });
  }, []);

  const unblindClickHandler = (idx: number) => {
    Swal.fire({
      title: "차단 해제하시겠습니까?",
      showDenyButton: true,
      confirmButtonText: "해제",
      denyButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.delete(`app/blind_user/${idx}`).then(() => {
          appSuccessMsg({ msg: "차단이 해제되었습니다." });
          setBlinds((bs) => bs.filter((b) => b.idx !== idx));
        });
      }
    });
  };

  return (
    <AppModal title="차단 관리" backClickHandler={() => navigate(-1)}>
      <Table>
        <tbody className="fs-7">
          <tr>
            <th>차단 닉네임</th>
            <th>차단 일자</th>
            <td></td>
          </tr>
          {blinds.map((blind) => (
            <tr key={blind.idx}>
              <td>{getUserNickName(blind.targetUser)}</td>
              <td>{blind.createdAt}</td>
              <td>
                <Button className="btn-sm" onClick={() => unblindClickHandler(blind.idx)}>
                  차단 해제
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </AppModal>
  );
};

export default SettingBlindList;
