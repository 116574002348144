import RouteChangeTracker from "RouteChangeTracker";
import { appSuccessMsg, isIos } from "assets/js/common";
import ImageGroupContainer, { ImageGroup } from "components/ImageGroup/ImageGroup";
import { NaverMapObject } from "components/NaverMap";
import Splash from "components/Splash/Splash";
import { MyComfirmContainer } from "components/modules/MyConfirm/MyConfirm";
import { MyToastContainer } from "components/modules/MyToast/MyToast";
import { APP_MODES, changeAppMode } from "features/appSlice";
import { BottomSheetItem } from "model/types";
import DeletePage from "pages/Auth/DeletePage";
import KakaoLogin from "pages/Auth/LoginButtons/KakaoLogin";
import NaverLogin from "pages/Auth/LoginButtons/NaverLogin";
import AppleLogin from "pages/Auth/LoginButtons/AppleLogin";
import SignTerm from "pages/Auth/SignTerm";
import SignUp from "pages/Auth/SignUp";
import Main from "pages/Main";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import { RootState } from "store/store";
import "styles/app.scss";

export let changeBackEvent = (props: null | Function) => {};

declare global {
  function GeoPermissionSet(e: any): void;
  function BackButtonClickEventListener(): void;
  function setCurrentPosition(latitude: any, longitude: any): void;
}

globalThis.BackButtonClickEventListener = () => {
  window.JavaScript.BackButtonClickEventListener && window.JavaScript.BackButtonClickEventListener();
};

globalThis.setCurrentPosition = (latitude: any, longitude: any) => {
  if (NaverMapObject.instance) {
    NaverMapObject.setGeoLocation({ latitude, longitude });
  }
};

globalThis.GeoPermissionSet = (e: any) => {
  window.JavaScript.GeoPermissionSet && window.JavaScript.GeoPermissionSet(e);
};

const User = () => {
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  RouteChangeTracker();

  if ((window as any).MobileAccessibility) {
    (window as any).MobileAccessibility.usePreferredTextZoom(false);
  }
  if (!window.JavaScript) window.JavaScript = {};

  useEffect(() => {
    let backCount = 0;

    window.JavaScript.BackButtonClickEventListener = () => {
      if (ImageGroup.isOpen()) {
        ImageGroup.close();
      } else if (window.location.pathname === "/") {
        if (appState.mode !== APP_MODES.DEFULAT) {
          dispatch(changeAppMode({ mode: APP_MODES.DEFULAT }));
        } else {
          if (backCount++ === 0) {
            appSuccessMsg({ msg: "'뒤로'버튼을 한번 더 누르시면 종료됩니다." });
          } else {
            if (window.JavaScript.CloseApp) window.JavaScript.CloseApp();
          }
          setTimeout(() => {
            backCount = 0;
          }, 1000);
        }
      } else {
        navigate(-1);
      }
    };
  }, [appState.mode, navigate, dispatch]);
  return (
    <>
      <ImageGroupContainer />
      <MyComfirmContainer />
      <MyToastContainer />
      <Routes>
        <Route path="sign/delete" element={<DeletePage />} />
        <Route path="kakao_redirect" element={<KakaoLogin />} />
        <Route path="naver_redirect" element={<NaverLogin />} />
        <Route path="apple_redirect" element={<AppleLogin />} />
        <Route
          path="sign/*"
          element={
            <>
              <SignUp />
              <Routes>
                <Route path="term/:termIdx" element={<SignTerm />} />
              </Routes>
            </>
          }
        />
        <Route path="*" element={<Map />} />
      </Routes>
    </>
  );
};

function* getTimeOutDelay() {
  const delay = 500;
  const minute = 3;
  for (let i = 0; i < (minute * 60) / (delay / 1000); i++) {
    yield 500;
  }
}

const Map = () => {
  const [isLoad, setLoad] = useState(false);
  const [isOrder, setIsOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(0);
  const [bottomSheetItems, setBottomSheetItems] = useState<BottomSheetItem[]>([]);
  const [isSplah, setIsSplash] = useState(window.name ? true : false);
  const [geoPermission, setGeoPermission] = useState(false);
  const geoPermissionRef = useRef(geoPermission);
  const iterator = getTimeOutDelay();

  useEffect(() => {
    geoPermissionRef.current = geoPermission;
  }, [geoPermission]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=hhni4cny9l&submodules=geocoder`;
    script.async = true;
    script.addEventListener("load", () => setLoad(true));
    document.body.appendChild(script);

    const checkPermission = () => {
      if (isIos()) {
        window.webkit.messageHandlers.GeoPermissionCheck &&
          window.webkit.messageHandlers.GeoPermissionCheck.postMessage("");
      } else {
        window.JavaScript.GeoPermissionCheck && window.JavaScript.GeoPermissionCheck();
      }

      const delay = geoPermissionRef.current ? 3000 : iterator.next().value ?? 1500;
      setTimeout(() => {
        checkPermission();
      }, delay);
    };

    window.JavaScript.GeoPermissionSet = (e: string) => {
      if (!NaverMapObject.userMarker && Boolean(parseInt(e))) {
        if (isIos()) {
          window.webkit.messageHandlers.setCurrentPosition &&
            window.webkit.messageHandlers.setCurrentPosition.postMessage("");
        } else {
          window.JavaScript.setCurrentPosition && window.JavaScript.setCurrentPosition();
        }
      }
      setGeoPermission(Boolean(parseInt(e)));
    };

    checkPermission();
  }, [iterator]);

  return (
    <BottomSheetContext.Provider
      value={{
        bottomSheetItems,
        setBottomSheetItems,
        isOrder,
        setIsOrder,
        orderBy,
        setOrderBy,
        geoPermission,
        setGeoPermission,
      }}
    >
      {isLoad && <Main />}
      {!isSplah && <Splash setIsSplash={setIsSplash} />}
    </BottomSheetContext.Provider>
  );
};

export default User;
