import { Col, Row } from "react-bootstrap";

const ModalDeleteAccount = () => {
  return (
    <Row className="flex-column p-3 m-0">
      <Col className="fs-5 mb-3 ps-2">서비스 탈퇴하기</Col>
      <Col className="mb-3 fs-7 p-0">회원 탈퇴 시 이용 내역 등 모든 정보가 삭제되어 복구가 불가능합니다.</Col>
      <Col className="fw-r fs-7 bg-gray p-2 text-brown-grey">
        <div className="mb-2">회원 탈퇴 시</div>
        <div className="fw-bold">부정 이용 기록회원 제재</div>
        <div className="mb-2">
          사용 계정의 경우 내부 방침에 의해 서비스 부정 이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴
          시점으로부터 최대 1년간 보관합니다. 블랙리스트로 제지를 받은 계정의 경우 서비스 재가입이 불가능합니다. 경고
          누적이 있는 계정의 경우 서비스 재가입은 가능하지만 경고 누적은 유지됩니다. 이의신청한 계정을 회원 탈퇴 시
          이의신청에 대한 기록은 사라지며, 제지 사항은 유지됩니다.
        </div>
        <div className="fw-bold">개인형 서비스 이용 기록 삭제</div>
        <div className="mb-2">
          회원정보 및 즐겨찾기 / 1:1 문의기록등 개인형 서비스 이용 기록은 모두 삭제되며, 삭제된 데이터는 복구되지
          않습니다.
        </div>
        <div className="fw-bold">리뷰 등록 게시물 유지</div>
        <div className="mb-2">
          “회원탈퇴 시 “회원”이 작성한 “게시물” 의 경우 삭제되지 않으니 삭제를 원하실 경우 사전에 삭제 후 탈퇴하시기
          바랍니다.
        </div>
      </Col>
    </Row>
  );
};

export default ModalDeleteAccount;
