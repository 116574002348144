import { appSuccessMsg, isIos, isUserLogin } from "assets/js/common";
import ModalDeleteAccount from "components/Modal/ModalDeleteAccount";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import { userLogout } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppleLoginButton } from "./LoginButtons/AppleLogin";
import { KakaoLoginButton } from "./LoginButtons/KakaoLogin";
import { NaverLoginButton } from "./LoginButtons/NaverLogin";

const DeletePage = () => {
  const dispatch = useDispatch();
  localStorage.removeItem("delete_auth");

  const deleteUser = () => {
    MyConfirm({
      text: <ModalDeleteAccount />,
      onOkClick: () => {
        Api.delete("/app/auth").then(() => {
          appSuccessMsg({ msg: "탈퇴되었습니다." });
          dispatch(userLogout());
        });
      },
    });
  };

  const logout = () => {
    appSuccessMsg({ msg: "로그아웃되었습니다." });
    dispatch(userLogout());
  };
  return (
    <div className="p-2 h-100 overflow-hidden">
      <h2 className="text-center">회원 탈퇴</h2>

      <div className="p-4 flex-column h-100">
        <Row className="login-btn-wrap p-4 h-100 flex-column">
          {!isUserLogin() && (
            <>
              <KakaoLoginButton deleted={true} />
              <NaverLoginButton />
              {isIos() && <AppleLoginButton />}
            </>
          )}
          <Col xs></Col>
          {isUserLogin() && (
            <>
              <Col xs="auto">
                <Button className="w-100" variant="warning" onClick={logout}>
                  로그아웃
                </Button>
              </Col>
              <Col xs="auto">
                <Button className="w-100" variant="danger" onClick={deleteUser}>
                  탈퇴 요청
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default DeletePage;
