import AppSearch from "assets/js/AppSearch";
import { elementToString, isIos } from "assets/js/common";
import ApiSearch from "components/modules/ApiSearch";
import { mapIdle } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { BottomSheetItem } from "model/types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "./BottomSheet/BottomSheetSearch";

const DEFAULT_MAP_ID = "map";
let setBottomSheetItemsByMap = (items: BottomSheetItem[]) => {};

export const MAP_ZOOM_MAX = 18;
export const MAP_ZOOM_MIN = 6;
export const MAP_ZOOM_DEFAULT = 18;

export const APP_MAP_SEARCH_MODES = {
  DEFAULT: 0,
  NAME: 1,
  REGION: 2,
  AGE: 3,
  QUESTION: 4,
  FIND: 11, // 단일 조회
};

export const ITEM_LEVEL = {
  SIDO: 0, // 시/도
  SIGUNGU: 1, // 시/군/구
  CUL: 2, // 문화재
};

const markers: naver.maps.Marker[] = [];
let geoWatchId: number = 0;
/** map idle 이벤트 생략 시 사용 */
let idleSkip = false;

interface NaverMapObjectInterFace {
  instance?: naver.maps.Map;
  userMarker?: naver.maps.Marker;
  searchMode: number;
  lastSearchMode?: number;
  preOption?: {};
  lastSearchOption: {
    idx?: number;
    itemLevel?: number;
    bounds?: naver.maps.Bounds;
    sido?: string;
    sigungu?: string;
    ccceName?: string;
    typeIdx?: number;
  };
  readonly zoomIncrease: Function;
  readonly zoomDecrease: Function;
  readonly moveByUserCenter: Function;
  search(event?: any): any;
  initOptions: Function;
  destroy: Function;
  focusMarker?: naver.maps.Marker;
  lastFocusMarkerIdxs?: naver.maps.Marker;
  setGeoLocation: Function;
}

export const NaverMapObject: NaverMapObjectInterFace = {
  instance: undefined,
  userMarker: undefined,
  searchMode: APP_MAP_SEARCH_MODES.DEFAULT,
  lastSearchOption: {},
  zoomIncrease: () => {
    let zoom = NaverMapObject.instance?.getZoom() ?? MAP_ZOOM_DEFAULT;
    if (++zoom <= MAP_ZOOM_MAX) NaverMapObject.instance?.setZoom(zoom, true);
  },
  zoomDecrease: () => {
    let zoom = NaverMapObject.instance?.getZoom() ?? MAP_ZOOM_DEFAULT;
    if (--zoom >= MAP_ZOOM_MIN) NaverMapObject.instance?.setZoom(zoom, true);
  },
  moveByUserCenter: () => {
    if (NaverMapObject.userMarker) {
      NaverMapObject.instance?.morph(NaverMapObject.userMarker.getPosition());
    } else {
      const lastPosition = getUserMarkerPosition();
      if (lastPosition) {
        NaverMapObject.userMarker = new naver.maps.Marker({
          position: lastPosition,
          icon: {
            content: `<div style="background-color: red; width: 1rem; height: 1rem; border-radius: 50%; margin-left: -50%; margin-top: -50%; pointer-events: none;"></div>`,
          },
          map: NaverMapObject.instance,
        });
        NaverMapObject.instance?.morph(lastPosition);
      }
      if (isIos()) {
        window.webkit.messageHandlers.setCurrentPosition &&
          window.webkit.messageHandlers.setCurrentPosition.postMessage("");
      } else {
        window.JavaScript.setCurrentPosition && window.JavaScript.setCurrentPosition();
      }
    }
  },
  search: async (props?: any) => {
    let data: any[] = [];
    let params: any = {};
    let level = props?.level ?? getItemLevel();

    if (props?.mode) NaverMapObject.searchMode = props?.mode;

    NaverMapObject.lastSearchMode = NaverMapObject.searchMode;
    switch (NaverMapObject.searchMode) {
      case APP_MAP_SEARCH_MODES.DEFAULT:
        if (!itemLevelCheck()) {
          if (getItemLevel() === ITEM_LEVEL.SIDO || !boundCheck()) return;
        }
        data = await ApiSearch().defaultSearch();
        break;

      case APP_MAP_SEARCH_MODES.REGION:
        params = {
          sido: props.sido ?? NaverMapObject.lastSearchOption.sido,
          sigungu: props.sigungu ?? NaverMapObject.lastSearchOption.sigungu,
          zoomLevel: props.level ?? getItemLevel(),
        };
        data = await ApiSearch().regionSearch(params);
        break;

      case APP_MAP_SEARCH_MODES.AGE:
        params = {
          ccceName: props.ccceName ?? NaverMapObject.lastSearchOption.ccceName,
          zoomLevel: getItemLevel(),
        };

        if (params.ccceName === NaverMapObject.lastSearchOption.ccceName && !itemLevelCheck()) {
          if (getItemLevel() === ITEM_LEVEL.SIDO || !boundCheck()) return;
        }

        data = await ApiSearch().ageSearch(params);
        break;

      case APP_MAP_SEARCH_MODES.QUESTION:
        params = {
          typeIdx: props.typeIdx ?? NaverMapObject.lastSearchOption.typeIdx,
          zoomLevel: getItemLevel(),
        };

        if (params.typeIdx === NaverMapObject.lastSearchOption.typeIdx && !itemLevelCheck()) {
          if (getItemLevel() === ITEM_LEVEL.SIDO || !boundCheck()) return;
        }

        data = await ApiSearch().questionSearch(params);
        break;
      case APP_MAP_SEARCH_MODES.FIND:
        if (!Number.isInteger(props.idx) || props.idx === NaverMapObject.lastSearchOption.idx) return;
        NaverMapObject.lastSearchOption.idx = props.idx;
        let result = await AppSearch.detail.idx(props.idx);
        if (result) {
          let latlng = new naver.maps.LatLng(result.latitude, result.longitude);
          NaverMapObject.instance?.morph(latlng, 18);
          data.push(result);
          level = ITEM_LEVEL.CUL;
          idleSkip = true;
        }
        break;

      default:
        break;
    }
    changeMarkers(data, level);
    return data;
  },
  initOptions: () => {
    NaverMapObject.searchMode = APP_MAP_SEARCH_MODES.DEFAULT;
    NaverMapObject.lastSearchMode = APP_MAP_SEARCH_MODES.DEFAULT;
    NaverMapObject.lastSearchOption = {};
  },
  destroy: () => {
    NaverMapObject.instance?.destroy();
    delete NaverMapObject.instance;
    delete NaverMapObject.userMarker;
    NaverMapObject.lastSearchOption = {};
    navigator.geolocation.clearWatch(geoWatchId);
  },
  setGeoLocation: (position: any) => {
    let latlng = new naver.maps.LatLng(position.latitude, position.longitude);
    setUserMarkerPosition(position.latitude, position.longitude);

    if (NaverMapObject.userMarker) {
      NaverMapObject.userMarker.setPosition(latlng);
    } else {
      NaverMapObject.userMarker = new naver.maps.Marker({
        position: latlng,
        icon: {
          content: `<div style="background-color: red; width: 1rem; height: 1rem; border-radius: 50%; margin-left: -50%; margin-top: -50%; pointer-events: none;"></div>`,
        },
        map: NaverMapObject.instance,
      });

      NaverMapObject.instance?.morph(latlng);
    }
  },
};

const changeMarkers = (data: Array<any>, level?: number) => {
  if (!level) level = getItemLevel();
  resetMarkers();
  for (const item of data) {
    let iconClass = "";
    if (
      NaverMapObject.searchMode === APP_MAP_SEARCH_MODES.AGE ||
      NaverMapObject.searchMode === APP_MAP_SEARCH_MODES.QUESTION
    ) {
      iconClass = "age-marker";
    } else if (NaverMapObject.searchMode === APP_MAP_SEARCH_MODES.REGION) {
      iconClass = "region-marker";
    }

    if (NaverMapObject.lastFocusMarkerIdxs && NaverMapObject.lastFocusMarkerIdxs === item.idxs) iconClass += " focus";

    let icon = MapIcon(item, level, iconClass);
    if (icon === false) continue;
    let marker = new naver.maps.Marker({
      position: new naver.maps.LatLng(item.latitude, item.longitude),
      icon: {
        content: elementToString(icon),
      },
      map: NaverMapObject.instance,
    });

    naver.maps.Event.addListener(marker, "click", () => markerClickHanler({ marker, item, level }));
    markers.push(marker);
  }
};

const markerClickHanler = ({ marker, item, level }: any) => {
  if (level === ITEM_LEVEL.CUL) {
    const icon = MapIcon(item, level, "focus") as JSX.Element;
    const focus = document.querySelector(".app-icon.focus");

    if (focus) focus.classList.remove("focus");
    if (NaverMapObject.focusMarker) NaverMapObject.focusMarker.setZIndex(0);

    marker.setIcon({ content: elementToString(icon) });
    marker.setZIndex(1);
    NaverMapObject.focusMarker = marker;

    if (item.idxs) {
      const idxs = typeof item.idxs === "string" ? item.idxs.split(",") : [item.idxs];
      NaverMapObject.lastFocusMarkerIdxs = item.idxs;
      BottomSheetSearch.items({ idxs, ...getMapCenter(), orderBy: 0 }).then(setBottomSheetItemsByMap);
    }
  } else if (level === ITEM_LEVEL.SIGUNGU) {
    const markerPosition = marker.getPosition();
    Api.get("/app/cultural_assets/coords_by_sigungu", {
      params: { lat: markerPosition.y, lng: markerPosition.x },
    }).then((response) => {
      NaverMapObject.instance?.morph(new naver.maps.LatLng(response.data.latitude, response.data.longitude), 14);
    });
  } else {
    NaverMapObject.instance?.morph(marker.getPosition(), 11);
  }
};

const resetMarkers = () => {
  if (markers.length) {
    let marker;
    while ((marker = markers.pop())) {
      marker.setMap(null);
    }
  }
};

const getItemLevel = () => {
  const zoom = NaverMapObject.instance?.getZoom() as number;
  switch (zoom) {
    /* 문화재 단위 */
    case 18: // 30m
    case 17: // 50m
    case 16: // 100m
    case 15: // 300m
    case 14: // 500m
      return ITEM_LEVEL.CUL;

    /* 시/군/구 단위 */
    case 13: // 1km
    case 12: // 3km
    case 11: // 5km
      return ITEM_LEVEL.SIGUNGU;

    /* 시/도 단위 */
    case 10: // 10km
    case 9: // 20km
    case 8: // 30km
    case 7: // 50km
    case 6: // 100km
      return ITEM_LEVEL.SIDO;
  }
};

const boundCheck = () => {
  if (getItemLevel() === ITEM_LEVEL.SIDO) return false;
  const bounds = NaverMapObject.instance?.getBounds() as naver.maps.Bounds;
  const result = !NaverMapObject.lastSearchOption.bounds || !NaverMapObject.lastSearchOption.bounds.equals(bounds);
  if (result) NaverMapObject.lastSearchOption.bounds = bounds;
  return result;
};

const itemLevelCheck = () => {
  const level = getItemLevel();
  const result = NaverMapObject.lastSearchOption.itemLevel !== level;
  if (result) NaverMapObject.lastSearchOption.itemLevel = level;
  return result;
};

const getBoundsFilter = () => {
  const bounds = NaverMapObject.instance?.getBounds() as naver.maps.Bounds;
  return {
    maxLng: bounds.maxX(),
    maxLat: bounds.maxY(),
    minLng: bounds.minX(),
    minLat: bounds.minY(),
  };
};

/**
 * 유저 마커 또는 지도 중앙 좌표 취득
 */
const getUserOrMapCenter = () => {
  let coord;
  if (NaverMapObject.userMarker) {
    coord = NaverMapObject.userMarker?.getPosition() as naver.maps.Coord;
  } else {
    let bounds = NaverMapObject.instance?.getBounds() as naver.maps.Bounds;
    coord = bounds.getCenter();
  }
  return {
    lng: coord.x,
    lat: coord.y,
  };
};

/**
 * 지도 중앙 좌표 취득
 */
const getMapCenter = () => {
  const bounds = NaverMapObject.instance?.getBounds() as naver.maps.Bounds;
  const coord = bounds.getCenter();
  return {
    lng: coord.x,
    lat: coord.y,
  };
};

const MapIcon = (item: any, level?: number, markerClass: string = ""): JSX.Element | false => {
  let param = {
    left_name: "",
    right_name: "",
    cnt: 1,
  };

  switch (level) {
    case ITEM_LEVEL.CUL:
      param.left_name = item.type;
      param.right_name = item.name;
      param.cnt = item.cnt || 1;
      break;

    default:
      param.left_name = item.cnt;
      param.right_name = item.name;
      break;
  }

  if (!param.left_name || !param.right_name) return false;
  return (
    <div className={`app-icon ${markerClass}`}>
      <div className="app-icon-left">{param.left_name}</div>
      <div className="app-icon-right">{param.right_name}</div>
      {param.cnt > 1 && <div className="marker-count">{`+${param.cnt - 1}`}</div>}
    </div>
  );
};

export default React.memo(() => {
  const dispatch = useDispatch();
  const { setBottomSheetItems, setIsOrder } = useContext(BottomSheetContext);
  const setBottomSheetItemsRef = useRef(setBottomSheetItems);
  const [logoWrap, setLogoWrap] = useState<JSX.Element>();

  setBottomSheetItemsByMap = (items: BottomSheetItem[]) => {
    setIsOrder(false);
    setBottomSheetItems(items);
  };

  useEffect(() => {
    setBottomSheetItemsRef.current = setBottomSheetItems;
  }, [setBottomSheetItems]);

  useEffect(() => {
    NaverMapObject.instance = new naver.maps.Map(DEFAULT_MAP_ID, {
      maxZoom: MAP_ZOOM_MAX,
      minZoom: MAP_ZOOM_MIN,
      zoom: MAP_ZOOM_DEFAULT,
      mapDataControl: false,
      center: new naver.maps.LatLng(37.559975221378, 126.975312652739),
    });

    // 지도 이동 중 문화재 삭제
    naver.maps.Event.addListener(NaverMapObject.instance, "dragstart", (event) => {
      setBottomSheetItemsRef.current([]);
    });

    // 지도 이동 시 문화재 검색
    naver.maps.Event.addListener(NaverMapObject.instance, "idle", (event) => {
      if (idleSkip) {
        idleSkip = false;
        return;
      }
      dispatch(mapIdle());
      NaverMapObject.search(event);
    });

    // 네이버 지도 로고 클릭 시 약관 표시
    naver.maps.Event.addListener(NaverMapObject.instance, "init", (event) => {
      NaverMapObject.moveByUserCenter();

      const naverLogos = document.getElementById(DEFAULT_MAP_ID)?.getElementsByTagName("a") as
        | [HTMLAnchorElement]
        | undefined;

      if (naverLogos) {
        const nLogo = naverLogos[0];
        const href = nLogo.href;
        nLogo.href = "#";
        nLogo.target = "";
        nLogo.addEventListener("click", () => {
          setLogoWrap(
            <div id="logo_html_wrap">
              <div>
                <Row className="m-1" style={{ minHeight: "2rem" }}>
                  <span className="arrow-prev ms-2" onClick={() => setLogoWrap(undefined)}></span>
                  <Col className="w-100 text-center mt-2">네이버 지도 약관</Col>
                </Row>
              </div>
              <iframe title="네이버 약관" src={href}></iframe>
            </div>
          );
        });
      }
    });

    return () => {
      NaverMapObject.destroy();
    };
  }, [dispatch]);

  return (
    <>
      {logoWrap && logoWrap}
      <div id={DEFAULT_MAP_ID} className="w-100 h-100" />
    </>
  );
});

interface GetAddressProps {
  lng: number;
  lat: number;
  callback: (status: naver.maps.Service.Status, response: naver.maps.Service.ReverseGeocodeResponse) => void;
}

const getAddress = ({ lng, lat, callback }: GetAddressProps) => {
  if (naver.maps.Service.reverseGeocode) {
    naver.maps.Service.reverseGeocode({ coords: new naver.maps.LatLng(lat, lng) }, (status, response) => {
      callback(status, response);
    });
  }
};

const USER_MARKER_LAST_POSITION = "USER_MARKER_LAST_POSITION";
const setUserMarkerPosition = (latitude: string, longitude: string) => {
  const expired = new Date();
  expired.setHours(expired.getHours() + 1);

  localStorage.setItem(
    USER_MARKER_LAST_POSITION,
    JSON.stringify({
      position: { latitude, longitude },
      expired,
    })
  );
};

const getUserMarkerPosition = () => {
  const str = localStorage.getItem(USER_MARKER_LAST_POSITION);

  if (str) {
    const json = JSON.parse(str);
    if (new Date() < new Date(json.expired)) {
      return new naver.maps.LatLng(json.position.latitude, json.position.longitude);
    }
  }
  return;
};

export { getAddress, getBoundsFilter, getItemLevel, getMapCenter, getUserOrMapCenter };
