import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
  title?: string;
  backClickHandler?: Function;
  className?: string;
}

const AppModal = (props: Props) => {
  const navigate = useNavigate();

  const className = "d-flex flex-column " + (props.className ? props.className : "");

  const BackButton = () => {
    if (props.backClickHandler != null)
      return (
        <span className="arrow-prev ms-2" onClick={(e) => props?.backClickHandler && props?.backClickHandler(e)}></span>
      );
    else return <span className="arrow-prev ms-2" onClick={() => navigate(-1)}></span>;
  };
  return (
    <div id="app_modal_wrap" className={className} style={{ right: `0px` }}>
      <Row className="m-1" style={{ minHeight: "2rem" }}>
        <BackButton />
        {props.title && <Col className="w-100 text-center mt-2">{props.title}</Col>}
      </Row>
      <div className="h-100 w-100 m-0 d-flex flex-column" style={{ overflowX: "hidden" }}>
        {props.children}
      </div>
    </div>
  );
};

export default AppModal;
