import { Api } from "lib/axios/Api";
import { Declaration, DeclarationType } from "model/Declaration";
import { Review, ReviewDetail } from "model/Review";
import { Badge, Button, Col, Form, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import { appErrorMsg, appSuccessMsg, getUserNickName, isBlackList, isUserLogin } from "assets/js/common";
import { CSSProperties } from "react";
import { ImageGroup } from "components/ImageGroup/ImageGroup";
import { REVIEW_STATUS_TYPES } from "constants/CONSTANTS";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";

interface ReviewByNomalProps {
  review: Review;
  setReviews: React.Dispatch<React.SetStateAction<Review[]>>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}
const ReviewByNomal = ({ review, setReviews, setCount }: ReviewByNomalProps) => {
  const appAuth = useSelector((state: RootState) => state.app.auth);
  const navigate = useNavigate();

  const deleteClickHandler = (review: Review) => {
    MyConfirm({
      text: "삭제하시겠습니까?",
      onOkClick: () => {
        Api.delete(`app/reviews/${review.idx}`).then(() => {
          setReviews((reviews) => reviews.filter((rev) => rev !== review));
          setCount((count: number) => count - 1);
          appSuccessMsg({ msg: "삭제되었습니다." });
        });
      },
    });
  };

  const blindClickHandler = (targetUserIdx: number) => {
    MyConfirm({
      text: "이 사용자가 작성한 글을 보지 않습니다.?",
      onOkClick: () => {
        Api.post(`/app/blind_user/${targetUserIdx}`).then((response) => {
          appSuccessMsg({ msg: "블라인드처리 되었습니다." });
          window.location.reload();
        });
      },
    });
  };

  const declarationClickHandler = async (review: Review) => {
    const types: DeclarationType[] = await Api.get("app/declaration_types").then((response) => response.data);

    MyConfirm({
      text: (
        <Row className="flex-column p-3">
          <Col className="fs-5 mb-3">신고하기</Col>
          <Col className="mb-3">
            <Form.Select
              className={`form-select fw-r text-brown-grey`}
              id="dec_select"
              onChange={(e) => {
                if (e.target.value !== "") e.target.classList.remove("text-brown-grey");
                else e.target.classList.add("text-brown-grey");
              }}
            >
              <option value="">선택하기</option>
              {types.map((type) => (
                <option value={type.idx} key={type.idx}>
                  {type.title}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col className="mb-3">
            <Form.Control
              as="textarea"
              className="noresize fw-r"
              rows={7}
              id="dec_text"
              placeholder="신고 내용을 기재해주세요."
            />
          </Col>
        </Row>
      ),
      onOkClick: () => {
        const decSelect = document.getElementById("dec_select") as HTMLSelectElement;
        const decText = document.getElementById("dec_text") as HTMLInputElement;

        if (decSelect.value === "") {
          appErrorMsg({ msg: "유형이 선택되지 않았습니다." });
          decSelect.focus();
          return false;
        }

        if (decText.value.trim().length === 0) {
          appErrorMsg({ msg: "신고 내용이 입력되지 않았습니다." });
          decText.focus();
          return false;
        }

        const declaration: Partial<Declaration> = {
          reviewIdx: review.idx,
          reviewDetailIdx: review.reviewDetails[0].idx,
          declarationTypeIdx: parseInt(decSelect.value),
          reason: decText.value,
        };

        Api.post("app/declaration_types", declaration).then((response) => {
          appSuccessMsg({ msg: "신고 처리 되었습니다." });
          setReviews((reviews) =>
            reviews.map((rev) => {
              if (rev.idx === review.idx) {
                rev.declarations?.push(response.data);
              }
              return rev;
            })
          );
        });
      },
    });
  };

  const ReviewBtnWrap = ({ review }: { review: Review }) => {
    const modifyClickHandler = async () => {
      if (await isBlackList()) {
        appErrorMsg({ msg: "블랙리스트 상태에서는 리뷰를 수정하실 수 없습니다." });
      } else {
        navigate(`/detail/${review.culturalAssetIdx}/review/${review.idx}`);
      }
    };

    if (appAuth.user.idx === review.userIdx) {
      if (review.status === REVIEW_STATUS_TYPES.NOMAL) {
        return (
          <Col className="text-end text-nowrap p-0">
            <Button
              className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
              variant="outline-brown-grey"
              onClick={modifyClickHandler}
            >
              수정
            </Button>
            <Button
              className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
              variant="outline-brown-grey"
              onClick={() => deleteClickHandler(review)}
            >
              삭제
            </Button>
          </Col>
        );
      } else {
        switch (review.status) {
          case REVIEW_STATUS_TYPES.BLIND:
            return <Col className="text-end text-nowrap text-secondary fs-7 p-0">블라인드</Col>;
          case REVIEW_STATUS_TYPES.WARNING:
            return <Col className="text-end text-nowrap text-warning fs-7 p-0">경고</Col>;
          case REVIEW_STATUS_TYPES.BLACKLIST:
            return <Col className="text-end text-nowrap text-danger fs-7 p-0">블랙리스트</Col>;
        }
      }
    } else {
      return (
        <>
          {isUserLogin() && (
            <Col className="text-end text-nowrap  p-0">
              <Button
                className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
                variant="outline-brown-grey"
                onClick={() => blindClickHandler(review.userIdx)}
              >
                이 사용자의 글 안보기
              </Button>
              {!review.declarations?.length && (
                <Button
                  className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
                  variant="outline-brown-grey"
                  onClick={() => declarationClickHandler(review)}
                >
                  신고
                </Button>
              )}
            </Col>
          )}
        </>
      );
    }
    return <></>;
  };

  const Header = () => {
    if (window.location.pathname === "/setting/mypage/review") {
      return (
        <Link to={`/detail/${review.culturalAsset.idx}`} className="text-dark text-decoration-none">
          {review.culturalAsset.ccbaMnm1}
        </Link>
      );
    } else {
      return <ReviewUserNickName />;
    }
  };

  const ReviewUserNickName = () => {
    if (appAuth.user.idx === review.userIdx) {
      return (
        <Row className="m-0 pt-1">
          <Col xs="auto" className="p-0">
            {getUserNickName(review.user)}
          </Col>
          <Col xs="auto" className="p-0" style={{ lineHeight: "1rem" }}>
            <Badge className="fs-8 ms-2" bg="dark-slate-blue" pill>
              나
            </Badge>
          </Col>
        </Row>
      );
    } else {
      return <div className="pt-1">{getUserNickName(review.user)}</div>;
    }
  };

  return (
    <Row className="flex-column border-bottom m-0 px-0 py-2">
      <Col className="mb-3 mt-1">
        <Row className="m-0">
          <Col className="fw-bold">
            <Header />
          </Col>
          <ReviewBtnWrap review={review} />
        </Row>
      </Col>
      <Col>
        <Row className="m-0 flex-column">
          <Col className="text-break fw-r fs-7 mb-3">{review.reviewDetails[0].text}</Col>
          <Col className="px-0">
            <ImageWrap reviewDetail={review.reviewDetails[0]} />
          </Col>
          <Col className="fs-7 text-brown-grey fw-r">{review.createdAt}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const ImageWrapStyle: CSSProperties = {
  maxHeight: "5rem",
  maxWidth: "7rem",
  position: "relative",
  flex: "0 0 auto",
};

const ImageStyle: CSSProperties = {
  maxHeight: "100%",
  maxWidth: "100%",
  objectFit: "contain",
};
const ImageWrap = ({ reviewDetail }: { reviewDetail: ReviewDetail }) => {
  const imageClickHandler = (imageNumber: number) => {
    const imageUrls = [];
    if (reviewDetail.reviewImageUrl1) imageUrls.push(reviewDetail.reviewImageUrl1);
    if (reviewDetail.reviewImageUrl2) imageUrls.push(reviewDetail.reviewImageUrl2);
    if (reviewDetail.reviewImageUrl3) imageUrls.push(reviewDetail.reviewImageUrl3);
    ImageGroup.setImages(imageUrls);
    ImageGroup.setIndex(imageNumber);
  };
  return (
    <Row className="m-0 overflow-auto flex-nowrap app-detail-image-wrap px-2">
      {reviewDetail.reviewImageUrl1 && (
        <Col style={ImageWrapStyle} className="border m-0 mb-2">
          <Image
            src={reviewDetail.reviewImageUrl1}
            style={ImageStyle}
            className="w-100"
            onClick={() => imageClickHandler(0)}
          />
        </Col>
      )}
      {reviewDetail.reviewImageUrl2 && (
        <Col style={ImageWrapStyle} className="border m-0 mb-2">
          <Image
            src={reviewDetail.reviewImageUrl2}
            style={ImageStyle}
            className="w-100"
            onClick={() => imageClickHandler(1)}
          />
        </Col>
      )}
      {reviewDetail.reviewImageUrl3 && (
        <Col style={ImageWrapStyle} className="border m-0 mb-2">
          <Image
            src={reviewDetail.reviewImageUrl3}
            style={ImageStyle}
            className="w-100"
            onClick={() => imageClickHandler(2)}
          />
        </Col>
      )}
    </Row>
  );
};

export default ReviewByNomal;
