import { ImageGroup } from "components/ImageGroup/ImageGroup";
import AppModal from "components/layouts/AppModal";
import { Api } from "lib/axios/Api";
import { Inquiry, InquiryColumn } from "model/types";
import { useEffect, useState } from "react";
import { Button, Col, FormLabel, Image, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const InquiryView = () => {
  const params = useParams();
  const { idx } = params;
  const [inquiry, setInquiry] = useState<Partial<Inquiry>>({});
  const navigate = useNavigate();

  useEffect(() => {
    Api.get(`/app/inquiry/${idx}`).then((response) => setInquiry(response.data));
  }, [idx]);

  const send = async () => {
    const { value } = await Swal.fire({
      input: "textarea",
      inputLabel: "추가 문의",
      inputPlaceholder: "문의 내용을 입력해주세요",
      inputAttributes: {
        "aria-label": "문의 내용을 입력해주세요",
        maxLength: "400",
      },
      inputValidator: (value) => {
        if (value.length > 400) return "400자 이하로 입력하여주세요.";
        return "";
      },
      showCancelButton: true,
    });

    if (value) {
      const columns: InquiryColumn[] = inquiry.inquiryColumns as InquiryColumn[];
      columns.push({ text: value });
      const params: Inquiry = {
        ...(inquiry as Inquiry),
        inquiryColumns: columns,
      };
      Api.put(`app/inquiry/${idx}`, { ...params }).then(() => window.location.reload());
    }
  };
  return (
    <AppModal title="문의작성" backClickHandler={() => navigate(-1)}>
      <Row className="flex-column flex-nowrap m-0 h-100 p-0">
        <Col className="col-auto">
          <Table className="table-borderless m-0">
            <tbody>
              <tr>
                <th className="col-3 text-nowrap">문의 상태:</th>
                <td>{inquiry.status === 0 ? "답변 대기" : "답변 완료"}</td>
              </tr>
              <tr>
                <th className="col-3 text-nowrap">문의 유형:</th>
                <td>{inquiry.type}</td>
              </tr>
              <tr>
                <th className="col-3 text-nowrap">등록일:</th>
                <td>{inquiry.createdAt}</td>
              </tr>
              <tr>
                <th className="col-3 text-nowrap">문의 제목:</th>
                <td>{inquiry.title}</td>
              </tr>
              {inquiry.imageUrl !== null && (
                <>
                  <tr>
                    <th className="col-3 text-nowrap">첨부 사진:</th>
                  </tr>
                  <tr>
                    <td>
                      <Image
                        src={inquiry.imageUrl}
                        className="border"
                        style={{ maxWidth: "30vw", maxHeight: "30vh" }}
                        onClick={() => {
                          ImageGroup.setImages([inquiry.imageUrl as string]);
                          ImageGroup.setIndex(0);
                        }}
                      />
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
        <Col className="mb-auto col-auto">
          {inquiry.inquiryColumns?.map((column) => (
            <Column column={column} key={column.idx} />
          ))}
        </Col>

        <Col className="p-2 col-auto">
          <Row>
            <Col>
              <Button className="w-100" variant="outline-primary" onClick={send}>
                추가 문의 등록
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </AppModal>
  );
};

function Column(props: { column: InquiryColumn }) {
  const { column } = props;
  if (column.type === 0)
    return (
      <Row className="my-4">
        <Col
          md="auto"
          className="border rounded border-primary p-4"
          style={{
            position: "relative",
            minWidth: "15rem",
            maxWidth: "60vw",
            marginLeft: "1rem",
            wordBreak: "break-all",
          }}
        >
          <FormLabel>{column.text}</FormLabel>
          <FormLabel
            className="bg-white px-2"
            style={{
              position: "absolute",
              bottom: "-1rem",
              right: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            {column.createdAt}
          </FormLabel>
        </Col>
      </Row>
    );
  else
    return (
      <Row className="my-4 justify-content-md-end">
        <Col
          md="auto"
          className="border rounded border-warning p-4 ms-auto"
          style={{
            position: "relative",
            minWidth: "15rem",
            maxWidth: "60vw",
            marginRight: "1rem",
            wordBreak: "break-all",
          }}
        >
          <FormLabel>{column.text}</FormLabel>
          <FormLabel
            className="bg-white px-2"
            style={{
              position: "absolute",
              bottom: "-1rem",
              right: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            {column.admin?.name}&nbsp;&nbsp;{column.createdAt}
          </FormLabel>
        </Col>
      </Row>
    );
}

export default InquiryView;
