import { APP_MODES, changeAppMode } from "features/appSlice";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "../BottomSheet/BottomSheetSearch";
import { APP_MAP_SEARCH_MODES, getMapCenter, NaverMapObject } from "../NaverMap";

const CCCE_NAMES = {
  선사시대: "선사",
  석기시대: "석기",
  청동시대: "청동",
  철기시대: "철기",
  삼한시대: "삼한",
  삼국시대: "삼국",
  고구려: "고구려",
  신라: "신라",
  백제: "백제",
  발해: "발해",
  통일신라: "통일신라",
  고려시대: "고려",
  조선시대: "조선",
  대한제국시대: "대한제국",
  일제강점기: "일제강점기",
};

const AgeFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBottomSheetItems, setIsOrder, orderBy } = useContext(BottomSheetContext);

  const clickHandler = (name: string, ccceName: string) => {
    NaverMapObject.searchMode = APP_MAP_SEARCH_MODES.AGE;
    NaverMapObject.search({ ccceName }).then(() => {
      setIsOrder(true);
      BottomSheetSearch.items({ ccceName, ...getMapCenter(), orderBy }).then(setBottomSheetItems);
    });
    navigate("/search_result", { state: { title: `시대별 검색 > ${name}` } });
    dispatch(changeAppMode({ mode: APP_MODES.FILTER_SEARCH }));
  };

  return (
    <div style={{ width: "100vw", backgroundColor: "#FFF" }} id="app_region_filter">
      <Row xs={1}>
        <SelectCol defaultText="시대별" />
      </Row>
      <Row xs={3} sm={5} md={6} id="app_region_filter_items">
        {Object.entries(CCCE_NAMES).map(([name, value], i) => (
          <Col className="p-3 border text-center" key={i} onClick={() => clickHandler(name, value)}>
            {name}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const SelectCol = ({ text, defaultText }: any) => {
  return <Col className={`px-3 py-2 border text-center fw-bold text-rouge`}>{text ?? defaultText}</Col>;
};

export default AgeFilter;
