import { CulturalAsset } from "model/CulturalAsset";
import { Col, Row } from "react-bootstrap";

interface Props {
  cul: CulturalAsset;
}
const DetailStory = ({ cul }: Props) => {
  return (
    <Row xs={1} className="p-3 m-0">
      {cul.culStory && (
        <>
          <Col className="mb-3">이야기 마당 URL 링크:</Col>
          <Col>
            <a href={cul.culStory.storyUrl} target="_blank" rel="noreferrer">
              {cul.culStory.storyUrl}
            </a>
          </Col>
        </>
      )}
      {cul.culStory == null && <Col className="m-1 pb-3 fs-5 text-center fw-bold text-muted">정보 없음</Col>}
    </Row>
  );
};

export default DetailStory;
