import { Image } from "react-bootstrap";
import "./Splash.scss";
import imgSpash1 from "assets/images/splash/splash_1.png";
import imgSpash2 from "assets/images/splash/splash_2.png";
import imgSpash3 from "assets/images/splash/splash_3.png";
import { useEffect, useRef } from "react";

const Splash = ({ setIsSplash }: any) => {
  const splash1 = useRef<HTMLImageElement>(null);
  const splash2 = useRef<HTMLImageElement>(null);
  const splash3 = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!splash1.current) return;
      setTimeout(() => {
        if (!splash2.current) return;
        let animation2 = splash2.current.animate([{ opacity: 1 }], {
          duration: 400,
          easing: "ease-out",
          iterations: 1,
          fill: "both",
        });

        animation2.onfinish = () => {
          setTimeout(() => {
            if (!splash3.current) return;
            let animation3 = splash3.current.animate([{ opacity: 1 }], {
              duration: 400,
              easing: "ease-out",
              iterations: 1,
              fill: "both",
            });

            animation3.onfinish = () => {
              setTimeout(() => {
                setIsSplash(true);
                window.name = "play_history";
              }, 1000);
            };
          }, 500);
        };
      }, 500);
    }, 300);
  }, [setIsSplash]);

  return (
    <div id="splash">
      <Image src={imgSpash1} className="splash-img" ref={splash1} id="splash_img_1" />
      <Image src={imgSpash2} className="splash-img" ref={splash2} id="splash_img_2" style={{ opacity: 0 }} />
      <Image src={imgSpash3} className="splash-img" ref={splash3} id="splash_img_3" style={{ opacity: 0 }} />
    </div>
  );
};

export default Splash;
