import { Col, Image, Row } from "react-bootstrap";
import { CSSProperties, useContext } from "react";
import "styles/app_bottom.scss";
import { useSelector } from "react-redux";
import { APP_MODES } from "features/appSlice";
import { appErrorMsg, isUserLogin } from "assets/js/common";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/store";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "components/BottomSheet/BottomSheetSearch";
import { getMapCenter } from "components/NaverMap";
import BottomSheet from "components/BottomSheet/BottomSheet";
import IconMarker from "assets/images/icon/icon_marker.png";
import IconStar from "assets/images/icon/icon_star.png";

const wrapStyle: CSSProperties = {
  boxShadow: "0px -2px 10px #888",
  zIndex: 2,
};

const buttonTextStyle: CSSProperties = {
  fontSize: ".8rem",
};

interface BottomButtonProps {
  icon: string;
  children: any;
  onClick?: any;
}

const BottomButton = (props: BottomButtonProps) => {
  return (
    <Col className="text-center" onClick={props.onClick}>
      <Image src={props.icon} width={25} />
      <br />
      <span style={buttonTextStyle}>{props.children}</span>
    </Col>
  );
};

const AppBottom = () => {
  const navigate = useNavigate();
  const appState = useSelector((state: RootState) => state.app);
  const { bottomSheetItems, setBottomSheetItems, setIsOrder, orderBy } = useContext(BottomSheetContext);

  const aroundClickHandler = () => {
    if (bottomSheetItems.length === 0) {
      setIsOrder(true);
      BottomSheetSearch.items({ ...getMapCenter(), orderBy }).then((rows) => {
        if (rows.length === 0) {
          appErrorMsg({ msg: "현재 위치 근처에 문화재가 없습니다. 지도를 움직여 보세요." });
        } else {
          setBottomSheetItems(rows);
        }
      });
    } else {
      setBottomSheetItems([]);
    }
  };

  const favoritesClickHandler = () => {
    if (isUserLogin()) navigate("/favorite");
    else appErrorMsg({ msg: "로그인이 필요한 서비스입니다." });
  };

  return (
    <div id="app_bottom">
      {appState.mode !== APP_MODES.FILTER_SEARCH && (
        <Row className="d-flex" style={wrapStyle} id="app_bottom_btns">
          <BottomButton icon={IconMarker} onClick={() => aroundClickHandler()}>
            주변
          </BottomButton>
          <BottomButton icon={IconStar} onClick={favoritesClickHandler}>
            즐겨찾기
          </BottomButton>
        </Row>
      )}
      <BottomSheet wrapClass={appState.mode === APP_MODES.FILTER_SEARCH ? "search" : ""} />
    </div>
  );
};

export default AppBottom;
