import { appErrorMsg, appSuccessMsg, getReviewImageArray } from "assets/js/common";
import { hasReviewImage } from "assets/js/common";
import { ImageGroup } from "components/ImageGroup/ImageGroup";
import ModalDeclaration, { getModalDeclarationData } from "components/Modal/ModalDeclaration";
import AppModal from "components/layouts/AppModal";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import { Api } from "lib/axios/Api";
import { Objection } from "model/Objection";
import { User, UserStatusLog } from "model/User";
import { CSSProperties, useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import iconArrowDown from "assets/images/icon/icon_arrow_down.png";

const MyDeclaration = () => {
  const [logs, setLogs] = useState<UserStatusLog[]>([]);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    Api.get("app/auth/my").then((response) => setUser(response.data));
    Api.get("app/user_status_logs/my").then((response) => {
      setLogs(response.data);
    });
  }, []);

  const convertStatus = () => {
    if (user)
      switch (user.status) {
        case 0:
          return "일반";
        case 1:
          return `경고 ${user.warningCnt}회`;
        case 2:
          return "블랙리스트";
      }
  };

  return (
    <AppModal title={`신고/블랙리스트 상태`}>
      <Row className="flex-column m-0 h-100">
        <Col className="text-center text-dark-slate-blue mb-3" xs="auto">
          {convertStatus()}
        </Col>
        <Col className="px-3 mb-3" xs="auto">
          <div className="bg-gray p-3 text-muted fw-r">
            <div className="fs-7 mb-2">경고 / 블랙리스트 상태</div>
            <div className="fs-7 mb-2">
              게시물 운영정책에 위배되는 경우 관리자의 판단 하에 경고 및 블랙리스트 처리됩니다.
            </div>
            <div className="fs-7 mb-2">경고 3회 이상 누적 시 자동으로 블랙리스트 처리됩니다.</div>
            <div className="fs-7">블랙리스트 회원의 경우 리뷰 작성이 불가능합니다.</div>
            <div className="fs-7 mb-2">(서비스 이용이 제한되지 않습니다)</div>
            <div className="fs-7">경고 및 블랙리스트는 이의 신청이 가능하며,</div>
            <div className="fs-7">
              이의신청이 인정될 경우 해당 게시물의 경고 및 블랙리스트 조치(경고 누적으로 인한 블랙리스트 포함)는
              취소됩니다.
            </div>
            <div className="fs-7 mb-2">
              ※ 단, 해당 게시물에 적용된 제지 사항에 대해서만 취소되며, 다른 게시물에 적용된 제지 사항은 유지합니다.
            </div>
          </div>
        </Col>
        <Col className="h-100 overflow-auto p-0">
          <Row className="w-100 m-0">
            {logs.map((log) => (
              <LogItem log={log} key={log.idx} />
            ))}
          </Row>
        </Col>
      </Row>
    </AppModal>
  );
};

const ImageWrapStyle: CSSProperties = {
  maxHeight: "7rem",
  maxWidth: "12rem",
  position: "relative",
  flex: "0 0 auto",
  margin: "0 .2rem",
};

const ImageStyle: CSSProperties = {
  maxHeight: "100%",
  maxWidth: "100%",
  objectFit: "contain",
};

const LogItem = ({ log }: { log: UserStatusLog }) => {
  const [isShow, setIsShow] = useState(false);

  const LogStatus = () => {
    const cancelClass = log.canceledAt ? "text-decoration-line-through" : "";
    if (log.status === 1) return <Col className={`text-warning fs-7 col-auto pt-1 ${cancelClass}`}>경고</Col>;
    else return <Col className={`text-danger fs-7 col-auto pt-1 ${cancelClass}`}>블랙리스트</Col>;
  };

  if (log.review) {
    return (
      <Col className="border py-0 px-1 w-100" xs="auto">
        <Row className="p-2 flex-column">
          <Col className="mb-2">
            <Row>
              <LogStatus />
              <Col className="fs-8" style={{ paddingTop: ".5rem" }}>
                {log.reason}
              </Col>
              <Col className="fs-8" xs="auto">
                <ObjectionBtn objection={log.objection} log={log} />
              </Col>
            </Row>
          </Col>
          {!isShow && (
            <Col className="me-3 fs-7">
              <Row>
                <Col className="text-truncate overflow-hidden">{log.review.reviewDetails[0].text}</Col>
                <Col className="col-auto fs-6 fw-bold" onClick={() => setIsShow(true)}>
                  <Image src={iconArrowDown} width={15} />
                </Col>
              </Row>
            </Col>
          )}
          {isShow && (
            <Col>
              <ReviewContent log={log} />
            </Col>
          )}
          <Col></Col>
        </Row>
      </Col>
    );
  }
  return <></>;
};

const ReviewContent = ({ log }: { log: UserStatusLog }) => {
  const imageClickHandler = (imageNumber: number) => {
    const imageUrls = log.review ? getReviewImageArray(log.review) : [];
    ImageGroup.setImages(imageUrls);
    ImageGroup.setIndex(imageNumber);
  };

  if (!log.review) return <></>;
  return (
    <Row className="flex-column">
      <Col className="me-3 fs-7 mb-3 text-break">{log.review.reviewDetails[0].text}</Col>
      {hasReviewImage(log.review) && (
        <Col>
          <Row className="m-0 overflow-auto flex-nowrap">
            {getReviewImageArray(log.review).map((url, i) => (
              <Col style={ImageWrapStyle} className="border" key={i}>
                <Image src={url} style={ImageStyle} className="w-100" onClick={() => imageClickHandler(0)} />
              </Col>
            ))}
          </Row>
        </Col>
      )}
      <Col className="text-secondary fs-7 mt-2">{log.createdAt} 적용일</Col>
    </Row>
  );
};

const ObjectionBtn = ({ objection, log }: { objection?: Objection; log: UserStatusLog }) => {
  const OnClickHandler = () => {
    MyConfirm({
      text: <ModalDeclaration />,
      onOkClick: () => {
        const text = getModalDeclarationData()[0];
        if (text.trim()) {
          Api.post("app/objections", { userStatusLogIdx: log.idx, text }).then((response) => {
            appSuccessMsg({ msg: "등록되었습니다." });
            window.location.reload();
          });
        } else {
          appErrorMsg({ msg: "내용이 입력되지 않았습니다." });
          return false;
        }
      },
    });
  };

  if (!objection) {
    return (
      <Button size="sm" variant="outline-dark-slate-blue" className="fs-8 " onClick={OnClickHandler}>
        이의신청
      </Button>
    );
  } else if (objection.status === 0) {
    return (
      <Button size="sm" variant="outline-dark-slate-blue" className="fs-8 " disabled>
        이의신청 완료
      </Button>
    );
  } else if (objection.status === 2) {
    <Button size="sm" variant="danger" className="fs-8 " disabled>
      이의신청 무효
    </Button>;
  }

  return <></>;
};

export default MyDeclaration;
