import AppSearch from "assets/js/AppSearch";
import { Api } from "lib/axios/Api";
import { getBoundsFilter, getItemLevel, ITEM_LEVEL, NaverMapObject } from "components/NaverMap";

const ApiSearch = () => {
  /** 기본 검색 */
  const defaultSearch = () => {
    const level = getItemLevel();
    switch (level) {
      case ITEM_LEVEL.SIDO:
        return AppSearch.level.sido();
      case ITEM_LEVEL.SIGUNGU:
        return AppSearch.level.sigungu(getBoundsFilter());
      case ITEM_LEVEL.CUL:
        return AppSearch.level.cul(getBoundsFilter());
    }
  };

  /** 지역 검색 */
  const regionSearch = ({ sido, sigungu, zoomLevel }: any) => {
    NaverMapObject.lastSearchOption.sido = sido;
    NaverMapObject.lastSearchOption.sigungu = sigungu;
    const params = {
      ccbaCtcdNm: sido,
      ccsiName: sigungu,
      zoomLevel,
      ...getBoundsFilter(),
    };

    return AppSearch.region.search(params);
  };

  const ageSearch = ({ ccceName, zoomLevel }: any) => {
    NaverMapObject.lastSearchOption.ccceName = ccceName;

    const params = {
      ccceName: ccceName,
      zoomLevel,
      ...getBoundsFilter(),
    };
    return AppSearch.age.search(params);
  };

  const questionSearch = ({ typeIdx, zoomLevel }: any) => {
    NaverMapObject.lastSearchOption.typeIdx = typeIdx;

    const level = getItemLevel();
    let params = { typeIdx, zoomLevel };
    if (level !== ITEM_LEVEL.SIDO) {
      params = { ...getBoundsFilter(), ...params };
    }
    return AppSearch.question.search(params);
  };

  const favorite = (params: any) => {
    return {
      around: () => Api.get(`/app/favorites/around`, { params }).then((response) => response.data),
      newest: () => Api.get(`/app/favorites/newest`, { params }).then((response) => response.data),
    };
  };

  const detail = async (idx: string) => {
    const response = await Api.get(`/app/cultural_assets/detail/${idx}`);
    return response.data;
  };

  return { defaultSearch, regionSearch, ageSearch, questionSearch, favorite, detail };
};

export default ApiSearch;
