import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import ApiSearch from "../../components/modules/ApiSearch";
import "styles/app_detail.scss";
import { CulturalAsset } from "model/CulturalAsset";
import DetailHeader from "./DetailHeader";
import DetailHome from "./Content/DetailHome";
import DetailPhoto from "./Content/DetailPhoto";
import DetailReview from "./Content/DetailReview";
import DetailStory from "./Content/DetailStory";
import AppModal from "components/layouts/AppModal";
import DetailFooter from "./DetailFooter";
import { Col, Row } from "react-bootstrap";

const DetailCul = () => {
  const { idx } = useParams() as { idx: string };
  const [cul, setCul] = useState<CulturalAsset>();

  useEffect(() => {
    ApiSearch()
      .detail(idx as string)
      .then((data) => setCul(data));
  }, [idx]);

  if (!cul) return <></>;

  return (
    <AppModal title={cul.ccbaMnm1}>
      <Row className="flex-column h-100">
        <Col xs="auto">
          <DetailHeader cul={cul} />
        </Col>
        <Col className="overflow-auto">
          <Routes>
            <Route path="*" element={<DetailHome cul={cul} />} />
            <Route path="photo" element={<DetailPhoto cul={cul} />} />
            <Route path="reviews" element={<DetailReview cul={cul} />} />
            <Route path="story" element={<DetailStory cul={cul} />} />
          </Routes>
        </Col>
        <Col xs="auto">
          <DetailFooter cul={cul} />
        </Col>
      </Row>
    </AppModal>
  );
};

export default DetailCul;
