import { checkScrollLength } from "assets/js/common";
import { BottomSheetItem } from "model/types";
import React from "react";
import { useContext } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "./BottomSheetSearch";
import QuestionBtn from "../QuestionBtn";

const BOTTOMSHEECT_ADMIN_COLORS = [
  "#6dbf90",
  "#999999",
  "#c67969",
  "#ddba6c",
  "#7c6b4b",
  "#9f82aa",
  "#9cc346",
  "#51746c",
  "#d37ea2",
];

interface BottomSheetContentListProps {
  items: BottomSheetItem[];
}

interface BottomSheetContentListProps {
  items: BottomSheetItem[];
  totalCnt: number;
  mode: number;
}

export const BottomSheetContentList = ({ items }: BottomSheetContentListProps) => {
  const { setBottomSheetItems } = useContext(BottomSheetContext);
  const navigate = useNavigate();

  const ContentClickHandler = (e: React.MouseEvent, idx: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (idx) navigate(`/detail/${idx}/`);
  };

  const moreSearch = async () => {
    const data = await BottomSheetSearch.more();
    setBottomSheetItems((list: BottomSheetItem[]) => [...list, ...data]);
  };

  const admins = Array.from(new Set(items.map((item) => item.ccbaAdmin)));

  const Content = (props: BottomSheetItem) => {
    const color = BOTTOMSHEECT_ADMIN_COLORS[admins.indexOf(props.ccbaAdmin) % BOTTOMSHEECT_ADMIN_COLORS.length];
    const moveQuestion = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(`/question/${props.idx}`);
    };

    return (
      <Row className="border-bottom p-2 fs-7 text-nowrap m-0" onClick={(e) => ContentClickHandler(e, props.idx)}>
        <Row className="d-flex flex-nowrap w-100 pe-0">
          <Col className="text-truncate pe-0 me-2" xs="auto" style={{ maxWidth: "calc(100% - 180px)" }}>
            <b className="m-0 fs-6 ">{props.ccbaMnm1}</b>
          </Col>
          <Col className="p-0 pt-1 me-3">
            <Badge pill style={{ maxWidth: "5rem", backgroundColor: color }} className="text-truncate pt-1" bg="">
              {props.ccbaAdmin}
            </Badge>
          </Col>
          <Col className="text-end text-warning p-0" xs="auto" onClick={moveQuestion}>
            {props.questionCnt > 0 && <QuestionBtn cnt={props.questionCnt} />}
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="fw-r">
            <span className="text-nowrap">
              {props.ccmaName} {props.crltsnoNm}호 / {props.ccceName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="fw-r">
            {props.ccbaCtcdNm} {props.ccsiName}
          </Col>
          <Col xs="auto" className="text-end p-0 flex-shrink-1 text-brown-grey">
            {Math.round(props.distance * 10) / 10} km
          </Col>
        </Row>
      </Row>
    );
  };

  let isCallApi = false;
  const scrollEventHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isCallApi) return;
    const target = e.target as HTMLDivElement;
    if (checkScrollLength(target.offsetHeight + target.scrollTop - target.scrollHeight)) {
      moreSearch().then(() => {
        isCallApi = true;
      });
    }
  };

  return (
    <>
      <hr className="m-0" />
      <div id="content_list_wrap" onScroll={scrollEventHandler}>
        <Row className="flex-column m-0">
          <Col className="p-0">{items && items.map((item) => <Content {...item} key={item.idx} />)}</Col>
          <Col className="p-3"></Col>
        </Row>
      </div>
    </>
  );
};
