import { useContext, useEffect, useState } from "react";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "../BottomSheet/BottomSheetSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { appErrorMsg } from "assets/js/common";

interface CustomizedState {
  title: string;
}

let lastTitle = "";

export const initLastTitle = () => {
  lastTitle = "";
};

export const FilterResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const { setBottomSheetItems } = useContext(BottomSheetContext);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    window.onpopstate = () => {
      initLastTitle();
    };

    if (isClose) {
      initLastTitle();
      navigate("/");
    }

    return () => {
      setIsClose(false);
      window.onpopstate = null;
    };
  }, [isClose, navigate, setBottomSheetItems]);

  const reSearch = () => {
    BottomSheetSearch.reSearch().then((rows) => {
      if (rows.length === 0) {
        appErrorMsg({ msg: "현재 위치 근처에 문화재가 없습니다. 지도를 움직여 보세요." });
      } else {
        setBottomSheetItems(rows);
      }
    });
  };

  if (state?.title) {
    lastTitle = state.title;
  }
  if (lastTitle === "") {
    setIsClose(() => true);
  }

  return (
    <>
      <div id="filter_result">
        <span>{lastTitle}</span>
        <button type="button" className="btn-close" aria-label="Close" onClick={() => setIsClose(() => true)}></button>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "1.5rem",
          textAlign: "center",
          width: "100%",
        }}
      >
        <div id="list_btn" onClick={reSearch}>
          목록보기
        </div>
      </div>
    </>
  );
};
