import { Api } from "lib/axios/Api";
import { CulturalAsset } from "model/CulturalAsset";
import { BlindUser, Review } from "model/Review";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReviewByBlind from "components/DetailReviews/ReviewByBlind";
import ReviewByDeclaration from "components/DetailReviews/ReviewByDeclaration";
import ReviewByNomal from "components/DetailReviews/ReviewByNomal";
import { appErrorMsg, isBlackList, isUserLogin } from "assets/js/common";

interface Props {
  cul: CulturalAsset;
}
const DetailReview = ({ cul }: Props) => {
  const [count, setCount] = useState(0);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [blindLIst, setBlindList] = useState<BlindUser[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    Api.get(`/app/reviews/count/${cul.idx}`).then((response) => {
      setCount(response.data);
    });

    const lastIdx = reviews.length ? reviews[reviews.length - 1].idx : null;
    Api.get(`/app/reviews/list/${cul.idx}`, { params: { lastIdx, pageSize: 10 } }).then((response) => {
      setReviews((r) => r.concat(response.data));
    });

    if (isUserLogin()) {
      Api.get(`/app/blind_user`).then((response) => setBlindList(response.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cul]);

  useEffect(() => {}, [blindLIst]);

  const createClickHandler = async () => {
    if (!isUserLogin()) {
      appErrorMsg({ msg: "로그인이 필요한 서비스입니다." });
      navigate("/setting/login");
    } else if (await isBlackList()) {
      appErrorMsg({ msg: "블랙리스트 상태에서는 리뷰를 작성하실 수 없습니다." });
    } else {
      navigate(`/detail/${cul.idx}/review`);
    }
  };

  /**
   * 리뷰
   * @see 우선순위: 블라인드 > 신고 > 일반
   */
  const ReviewRow = ({ review }: { review: Review }) => {
    if (blindLIst.filter((blind) => blind.targetUserIdx === review.userIdx).length) return <ReviewByBlind />;
    else if (review.declarations?.length)
      return <ReviewByDeclaration review={review} setReviews={setReviews} setCount={setCount} />;
    else return <ReviewByNomal review={review} setReviews={setReviews} setCount={setCount} />;
  };

  return (
    <Row className="contens_wrap" xs={1}>
      <Col className="text-end p-3 pb-0">
        <Row className="justify-content-between border-bottom pb-3">
          <Col className="text-start fw-bold m-auto ps-4" xs="auto">
            리뷰 <span className="text-dark-slate-blue">{count}</span>
          </Col>
          <Col>
            <Button className="btn-sm fs-7 px-3 py-2" onClick={createClickHandler} variant="dark-slate-blue">
              리뷰쓰기
            </Button>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          {reviews.map((review) => (
            <ReviewRow review={review} key={review.idx} />
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default DetailReview;
