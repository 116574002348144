import { configureStore } from "@reduxjs/toolkit";
import appReducer from "features/appSlice";

const authMiddleware = (store: any) => (next: any) => (action: any) => {
  return next(action);
};

const store = configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
