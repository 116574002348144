import { appErrorMsg, appSuccessMsg, existsUserInfo } from "assets/js/common";
import { userLogin } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import KakaoLoginBtnImage from "assets/images/sns/kakao_login_btn.png";

const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code&scope=account_email,gender`;
export const KakaoLoginButton = ({ deleted = false }: { deleted?: boolean }) => {
  if (deleted) {
    localStorage.setItem(
      "delete_auth",
      JSON.stringify({
        date: new Date(),
      })
    );
  }

  return (
    <div style={{ backgroundColor: "#FEE500" }}>
      <a href={KAKAO_AUTH_URL} className="w-100">
        <Image src={KakaoLoginBtnImage} />
      </a>
    </div>
  );
};

const KakaoLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("code", searchParams.get("code") as string);
    Api.post("/app/kakao/token", params)
      .then((response) => {
        const delete_auth = localStorage.getItem("delete_auth");
        localStorage.removeItem("delete_auth");
        appSuccessMsg({ msg: "로그인되었습니다." });
        dispatch(userLogin({ userToken: response.data }));

        // 탈퇴 페이지
        if (delete_auth) {
          navigate("/sign/delete");

          // 로그인 성공
        } else {
          navigate("/");
        }
      })
      .catch((e) => {
        const delete_auth = localStorage.getItem("delete_auth");
        localStorage.removeItem("delete_auth");

        // 탈퇴 페이지
        if (delete_auth) {
          appErrorMsg({ msg: "가입되어 있지 않은 계정입니다." });
          navigate("/sign/delete");

          // 미가입 회원
        } else if (!existsUserInfo(e)) {
          navigate("/sign", { state: e.response.data.body });

          // 에러
        } else {
          navigate("/setting/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="app_modal_wrap" className="d-flex flex-column" style={{ right: `0px` }}></div>;
};

export default KakaoLogin;
