import { commonSwalConfirm } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { BottomSheetItem } from "model/types";
import { Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QuestionBtn from "./QuestionBtn";
import closeIcon from "assets/images/icon/icon_close.png";

interface Props {
  cul: BottomSheetItem;
  reload: Function;
  setFavoriteCount?: Function;
}

const CulItem = ({ cul, reload, setFavoriteCount }: Props) => {
  const navigate = useNavigate();

  const culClickHandler = () => {
    navigate(`/detail/${cul.idx}`);
  };

  const questionClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/question/${cul.idx}`);
  };

  const deleteClickHandler = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    const result = await commonSwalConfirm({ title: "삭제하시겠습니까?", confirmButtonText: "삭제" });
    if (result.isConfirmed) {
      Api.delete(`app/favorites/${cul.idx}`).then(() => {
        if (setFavoriteCount) setFavoriteCount((n: number) => n - 1);
        reload();
      });
    }
  };

  return (
    <Row className="border-bottom px-3 py-2 fs-7 text-nowrap m-0 flex-nowrap" onClick={culClickHandler}>
      <Col xs="auto" className="p-2 m-0">
        <Row className="align-items-center h-100">
          <Col xs="auto">
            <span className="fs-4" onClick={deleteClickHandler}>
              <Image src={closeIcon} height={"10px"} />
            </span>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="d-flex flex-nowrap">
          <Col className="w-100 text-truncate">
            <b className="m-0 fs-6 ">{cul.ccbaMnm1}</b>
          </Col>
          {cul.questionCnt > 0 && (
            <Col className="text-end text-warning p-0 col-auto" onClick={questionClickHandler}>
              <QuestionBtn cnt={cul.questionCnt} />
            </Col>
          )}
        </Row>
        <Row className="mb-1">
          <Col className="text-nowrap text-truncate fw-r">
            {cul.ccmaName} {cul.crltsnoNm}호 / {cul.ccceName}
          </Col>
        </Row>
        <Row>
          <Col className="fw-r">
            {cul.ccbaCtcdNm} {cul.ccsiName}
          </Col>
          {cul.distance !== undefined && (
            <Col xs="auto" className="text-end p-0 flex-shrink-1 text-brown-grey">
              {Math.round(cul.distance * 10) / 10} km
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default CulItem;
