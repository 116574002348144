import AppModal from "components/layouts/AppModal";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import { Api } from "lib/axios/Api";
import { Question, QuestionType } from "model/Question";
import { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const QuestionView = () => {
  const { idx } = useParams();
  const navigate = useNavigate();
  const [questionsList, setQuestionsList] = useState<Question[][]>([]);
  const [questionTypes, setQuestionTypes] = useState<QuestionType[]>([]);
  const [selectType, setSelectType] = useState<number | null>(null);

  useEffect(() => {
    Api.get(`/app/question/types/${idx}`).then((response) => setQuestionTypes(response.data));
  }, [idx]);

  useEffect(() => {
    Api.get(`app/question/${idx}`, { params: { typeIdx: selectType } }).then((response) => {
      const array: Question[][] = [];
      let lastTypeIdx = 0;

      for (const question of response.data) {
        if (lastTypeIdx !== question.questionType.idx) {
          lastTypeIdx = question.questionType.idx;
          array.push([]);
        }
        array[array.length - 1].push(question);
      }
      setQuestionsList(array);
    });
  }, [idx, selectType]);

  return (
    <AppModal title="기출 문제" backClickHandler={() => navigate(-1)}>
      <Row className="flex-column p-0 m-0">
        <Col className="p-2 ps-4 border-bottom">
          <Row className="text-start">
            <Col className="col-auto m-0 p-0">
              <Form.Check
                type="radio"
                label="전체"
                style={{ transform: "scale(.8)" }}
                name="question_type"
                defaultChecked
                onChange={() => setSelectType(null)}
                color="dark-slate-blue"
              />
            </Col>
            {questionTypes.map((questionType) => (
              <Col className="col-auto m-0 p-0" key={questionType.idx}>
                <Form.Check
                  type="radio"
                  label={questionType.name}
                  style={{ transform: "scale(.8)" }}
                  name="question_type"
                  onChange={() => setSelectType(questionType.idx)}
                  color="dark-slate-blue"
                />
              </Col>
            ))}
          </Row>
        </Col>
        {questionsList.map((questions, key) => (
          <Col key={key} className="px-4 pt-3">
            <Row>
              <Col className="text-rouge mb-2">{questions[0].questionType && questions[0].questionType.name}</Col>
            </Row>
            {questions.map((question) => (
              <Row className="m-0" key={question.idx}>
                <QuestionRow question={question} />
              </Row>
            ))}
          </Col>
        ))}
        <Col></Col>
      </Row>
    </AppModal>
  );
};

interface QuestionRowProps {
  question: Question;
}
const QuestionRow = ({ question }: QuestionRowProps) => {
  const Content = () => {
    if (question.contentImg)
      return <Image src={process.env.REACT_APP_API_URL + question.contentImg} style={{ width: "100%" }} />;
    else return <div className="p-2">{question.content}</div>;
  };

  const showAnswer = () => {
    MyConfirm({
      text: question.answer,
      showCancelBtn: false,
    });
  };

  return (
    <Row className="flex-column m-0 p-2">
      <Col className="fs-6 p-0">
        <Row className="border py-2 m-0">
          <Col className="pt-1">
            <span>
              {question.questionTypeColumn?.date.substring(0, 4)} {question.questionTypeColumn?.cnt}
            </span>
            <span className="me-3">회차</span>
            <span className="fw-bold">{question.questionTypeLevel?.text ?? ""}</span>
          </Col>
          <Col className="text-end" xs="auto">
            <Button variant="dark-slate-blue" onClick={showAnswer} size="sm" className="fs-7">
              정답 보기
            </Button>
          </Col>
        </Row>
      </Col>
      <Col className="mb-3 p-0">
        <Content />
      </Col>
    </Row>
  );
};

export default QuestionView;
