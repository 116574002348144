import { animationByRef } from "assets/js/common";
import React, { useEffect, useRef, useState } from "react";
import "styles/my_image_group.scss";
import iconArrowLeft from "assets/images/icon/icon_arrow_left.jpg";
import iconArrowRight from "assets/images/icon/icon_arrow_right.jpg";
import closeIcon from "assets/images/icon/icon_close.png";
import { Image } from "react-bootstrap";

interface ImageGroupInterface {
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  isOpen: () => boolean;
  close: () => void;
}

export const ImageGroup: ImageGroupInterface = {
  setImages: () => {},
  setIndex: () => {},
  isOpen: () => false,
  close: () => {},
};

const ImageGroupContainer = () => {
  const [images, setImages] = useState<string[]>([]);
  const [index, setIndex] = useState<number>(0);
  const imageRef = useRef<HTMLDivElement>(null);
  const imageWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ImageGroup.setImages = setImages;
    ImageGroup.setIndex = setIndex;
    ImageGroup.close = () => {
      setImages([]);
    };
  }, []);

  useEffect(() => {
    ImageGroup.isOpen = () => images.length > 0;
  }, [images]);

  const preClickHandler = () => {
    imageInit();
    setIndex((i) => i - 1);
  };

  const nextClickHandler = () => {
    imageInit();
    setIndex((i) => i + 1);
  };

  const imageInit = () => {
    if (imageRef.current) {
      imageRef.current.style.width = "100%";
      imageRef.current.style.height = "100%";
    }
  };

  let tapedTwice = false;
  const imgTouchHandler = (e: React.TouchEvent) => {
    if (!imageRef.current || !imageWrapRef.current) return;
    if (!tapedTwice) {
      tapedTwice = true;
      setTimeout(() => {
        tapedTwice = false;
      }, 200);
    } else {
      const zoom = imageRef.current.style.width === "100%" ? "200%" : "100%";

      animationByRef({
        refObject: imageRef,
        effect: [{ width: zoom, height: zoom }],
      });
    }
  };

  if (images.length <= 0) return <></>;

  return (
    <div id="image_full_screen">
      <div id="image_full_screen_header" onClick={ImageGroup.close}>
        <Image src={closeIcon} height={"20px"} />
      </div>
      <div id="image_full_screen_content" ref={imageWrapRef}>
        <div
          id="full_screen_content_img"
          style={{ width: "100%", height: "100%", backgroundImage: `url(${images[index]})` }}
          onTouchStart={imgTouchHandler}
          ref={imageRef}
        ></div>
        {images[index].indexOf("www.cha.go.kr") >= 0 && (
          <div className="text-end text-secondary fs-7 pe-3" style={{ position: "absolute", right: 0, bottom: 0 }}>
            이미지 출처 : 문화재 청
          </div>
        )}
        {index > 0 && (
          <div id="full_screen_content_pre" onClick={preClickHandler}>
            <Image src={iconArrowLeft} />
          </div>
        )}
        {index < images.length - 1 && (
          <div id="full_screen_content_next" onClick={nextClickHandler}>
            <Image src={iconArrowRight} />
          </div>
        )}
      </div>
      <div id="image_full_screen_footer">
        {index + 1} / {images.length}
      </div>
    </div>
  );
};

export default ImageGroupContainer;
