import { NaverMapObject } from "components/NaverMap";
import { APP_MODES, changeAppMode } from "features/appSlice";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import BottomSheetContext from "shared/contexts/BottomSheetContext";

const Home = () => {
  const dispatch = useDispatch();
  const { setBottomSheetItems } = useContext(BottomSheetContext);

  useEffect(() => {
    setBottomSheetItems([]);
    NaverMapObject.initOptions();
    NaverMapObject.search();
    dispatch(changeAppMode({ mode: APP_MODES.DEFULAT }));
  }, [dispatch, setBottomSheetItems]);

  return <></>;
};

export default Home;
