import { CSSProperties, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ApiSearch from "./ApiSearch";
import { Api } from "lib/axios/Api";
import AppModal from "components/layouts/AppModal";
import { getUserOrMapCenter } from "components/NaverMap";
import CulItem from "components/CulItem";
import { BottomSheetItem } from "model/types";
import { checkScrollLength } from "assets/js/common";

const loadingStyle: CSSProperties = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const Favorite = () => {
  const [rows, setRows] = useState<BottomSheetItem[]>([]);
  const [pageNum, setPageNum] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [searchMode, setSearchMode] = useState<number>(0);
  const [params, setParams] = useState({});

  useEffect(() => {
    Api.get("app/favorites/count/my").then((response) => setTotal(response.data));
    setParams({ ...getUserOrMapCenter() });
  }, []);

  useEffect(() => {
    switch (searchMode) {
      case 0:
        ApiSearch()
          .favorite({ ...params, pageNum: 0 })
          .newest()
          .then((result) => setRows(result));
        break;
      case 1:
        ApiSearch()
          .favorite({ ...params, pageNum: 0 })
          .around()
          .then((result) => setRows(result));
        break;
    }
  }, [searchMode, params]);

  const searchChange = (mode: number) => {
    setPageNum(0);
    setSearchMode(mode);
  };

  let isCallApi = false;
  const scrollEventHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isCallApi || total <= rows.length) return false;
    const target = e.target as HTMLDivElement;
    if (checkScrollLength(target.offsetHeight + target.scrollTop - target.scrollHeight)) {
      switch (searchMode) {
        case 0:
          ApiSearch()
            .favorite({ ...params, pageNum: pageNum + 1 })
            .newest()
            .then((result) => {
              setRows((r) => [...r, ...result]);
              setPageNum((n) => n + 1);
              isCallApi = true;
            });
          break;
        case 1:
          ApiSearch()
            .favorite({ ...params, pageNum: pageNum + 1 })
            .around()
            .then((result) => {
              setRows((r) => [...r, ...result]);
              setPageNum((n) => n + 1);
              isCallApi = true;
            });
          break;
      }

      isCallApi = true;
    }
  };

  const reload = () => {
    switch (searchMode) {
      case 0:
        ApiSearch()
          .favorite({ ...params, pageNum: 0, pageSize: rows.length })
          .newest()
          .then((result) => {
            setRows(result);
          });
        break;
      case 1:
        ApiSearch()
          .favorite({ ...params, pageNum: 0, pageSize: rows.length })
          .around()
          .then((result) => {
            setRows(result);
          });
        break;
    }
  };

  const Contents = () => {
    if (!rows.length) {
      return <div style={loadingStyle}>즐겨찾기가 없습니다.</div>;
    } else {
      return (
        <>
          {rows.map((row) => (
            <CulItem cul={row} key={row.idx} reload={reload} />
          ))}
        </>
      );
    }
  };

  return (
    <AppModal title={`즐겨찾기(${total})`}>
      <Row className="flex-column m-0 h-100">
        <Col className="p-2 ps-3 col-auto fs-7 border-bottom mt-2">
          <Form.Check
            inline
            name="search_type"
            label="최신순"
            type="radio"
            defaultChecked={true}
            onChange={() => searchChange(0)}
            color="dark-slate-blue"
          />
          <Form.Check
            inline
            name="search_type"
            label="거리순"
            type="radio"
            onChange={() => searchChange(1)}
            color="dark-slate-blue"
          />
        </Col>
        <Col className="p-0 overflow-auto" onScroll={scrollEventHandler}>
          <Contents />
        </Col>
      </Row>
    </AppModal>
  );
};

export default Favorite;
