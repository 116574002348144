import AppSearch from "assets/js/AppSearch";
import { checkScrollLength } from "assets/js/common";
import { APP_MODES, changeAppMode } from "features/appSlice";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "../BottomSheet/BottomSheetSearch";
import { APP_MAP_SEARCH_MODES, getMapCenter, NaverMapObject } from "../NaverMap";

interface CulInterFace {
  idx: number;
  ccbaMnm1: string; // 문화재명
  ccbaLcad: string; // 상세주소
}

const SearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const [rows, setRows] = useState<CulInterFace[]>([]);
  const [pageNum, setPageNum] = useState<number>(0);
  const { setBottomSheetItems, setIsOrder, orderBy } = useContext(BottomSheetContext);

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const SearchChangerHandler = (el: any) => {
    let lastText = el.target.value;

    setTimeout(async () => {
      if (lastText === "") {
        setRows([]);
      } else if (lastText === el.target.value) {
        const result = await AppSearch.search.name(el.target.value, pageNum);
        setRows(result);
      }
    }, 500);
  };

  const culCLickHandler = (row: CulInterFace) => {
    NaverMapObject.search({ idx: row.idx, mode: APP_MAP_SEARCH_MODES.FIND });
    setIsOrder(false);
    BottomSheetSearch.items({ idxs: [row.idx], ...getMapCenter(), orderBy }).then(setBottomSheetItems);
    dispatch(changeAppMode({ mode: APP_MODES.FILTER_SEARCH }));
    navigate("/search_result", { state: { title: row.ccbaMnm1 } });
    return false;
  };

  let isCallApi = false;
  const scrollEventHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isCallApi || !searchRef.current) return;
    const target = e.target as HTMLDivElement;
    if (checkScrollLength(target.offsetHeight + target.scrollTop - target.scrollHeight)) {
      AppSearch.search.name(searchRef.current.value, pageNum + 1).then((result) => {
        setRows((r) => {
          return [...r, ...result];
        });
        setPageNum((pageNum) => pageNum + 1);
        isCallApi = true;
      });
    }
  };

  return (
    <div className="d-flex flex-column h-100 w-100 p-0">
      <Row className="p-3 flex-column">
        <Col xs="auto" className="mb-5">
          <Link to="/">
            <span className="arrow-prev" />
          </Link>
        </Col>
        <Col className="px-1">
          <Form.Control id="name_search" placeholder="문화재 명 검색" ref={searchRef} onChange={SearchChangerHandler} />
        </Col>
      </Row>
      {rows.length > 0 && (
        <>
          <ListGroup className="overflow-auto h-100" onScroll={scrollEventHandler}>
            {rows.map((row: CulInterFace) => (
              <ListGroupItem onClick={() => culCLickHandler(row)} key={row.idx}>
                <Row>
                  <Col>{row.ccbaMnm1}</Col>
                </Row>
                <Row>
                  <Col className="fs-7 text-muted">{row.ccbaLcad ?? "상세주소 없음"}</Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </>
      )}
    </div>
  );
};

export default SearchPage;
