import AppModal from "components/layouts/AppModal";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { User } from "model/User";
import { Api } from "lib/axios/Api";
import { Link, useNavigate } from "react-router-dom";
import MyContent from "../MyContent";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import ModalDeleteAccount from "components/Modal/ModalDeleteAccount";
import { appSuccessMsg } from "assets/js/common";
import { useDispatch } from "react-redux";
import { userLogout } from "features/appSlice";

const MyPage = () => {
  const [user, setUser] = useState<User>();
  const [reviewCount, setReviewCount] = useState<number>();
  const [favoriteCount, setFavoriteCount] = useState<number>();
  const [blindCount, setBlindCount] = useState<number>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    Api.get("app/auth/my").then((response) => setUser(response.data));
    Api.get("app/reviews/count/my").then((response) => setReviewCount(response.data));
    Api.get("app/favorites/count/my").then((response) => setFavoriteCount(response.data));
    Api.get("app/blind_user/count").then((response) => setBlindCount(response.data));
  }, []);

  const convertStatus = () => {
    if (user)
      switch (user.status) {
        case 0:
          return "일반";
        case 1:
          return `경고 ${user.warningCnt}회`;
        case 2:
          return "블랙리스트";
      }
  };

  const deleteAccountClickHandler = () => {
    MyConfirm({
      text: <ModalDeleteAccount />,
      onOkClick: () => {
        Api.delete("/app/auth").then(() => {
          appSuccessMsg({ msg: "탈퇴되었습니다." });
          dispatch(userLogout());
          navigate("/");
        });
      },
    });
  };

  return (
    <AppModal title="마이페이지">
      <Row className="flex-column h-100 m-0">
        <Col xs="auto" className="px-2">
          <MyContent isLogOutBtn={true} />
        </Col>
        <Col className="p-0">
          <Row className="flex-column m-0">
            <Col className="border p-3">
              <Link to={"review"} className="text-dark text-decoration-none">
                <Row>
                  <Col>내가 쓴 리뷰글</Col>
                  <Col className="text-end text-dark-slate-blue">{reviewCount} &gt;</Col>
                </Row>
              </Link>
            </Col>
          </Row>
          <Row className="flex-column m-0">
            <Col className="border-bottom p-3">
              <Link to={"declaration"} className="text-dark text-decoration-none">
                <Row>
                  <Col>신고/블랙리스트 상태</Col>
                  <Col className="text-end text-dark-slate-blue">{convertStatus()} &gt;</Col>
                </Row>
              </Link>
            </Col>
          </Row>
          <Row className="flex-column m-0">
            <Col className="border-bottom p-3">
              <Link to={"/favorite"} className="text-dark text-decoration-none">
                <Row>
                  <Col>즐겨찾기</Col>
                  <Col className="text-end text-dark-slate-blue">{favoriteCount} &gt;</Col>
                </Row>
              </Link>
            </Col>
          </Row>
          <Row className="flex-column m-0">
            <Col className="border-bottom p-3">
              <Link to={"/setting/blind_list"} className="text-dark text-decoration-none">
                <Row>
                  <Col>차단관리</Col>
                  <Col className="text-end text-dark-slate-blue">{blindCount} &gt;</Col>
                </Row>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col className="col-auto mt-auto">
          <Row className="w-100 m-0 text-end mb-3 fw-r">
            <Col className="fs-7 text-brown-grey text-decoration-underline" onClick={deleteAccountClickHandler}>
              서비스 탈퇴하기
            </Col>
          </Row>
        </Col>
      </Row>
    </AppModal>
  );
};

export default MyPage;
