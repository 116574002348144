import AppSearch from "assets/js/AppSearch";
import { SIDOS } from "constants/CONSTANTS";
import { APP_MODES, changeAppMode } from "features/appSlice";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "../BottomSheet/BottomSheetSearch";
import { APP_MAP_SEARCH_MODES, getMapCenter, ITEM_LEVEL, NaverMapObject } from "../NaverMap";

const RegionFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sido, setSido] = useState<String | null>(null);
  const [sigungu, setSigungu] = useState<String | null>(null);
  const [sigunguList, setSigunguList] = useState<String[]>([]);
  const [mode, setMode] = useState(0);
  const { setBottomSheetItems, setIsOrder, orderBy } = useContext(BottomSheetContext);

  useEffect(() => {
    if (mode === 2) {
      NaverMapObject.searchMode = APP_MAP_SEARCH_MODES.REGION;
      NaverMapObject.search({ sido, sigungu, level: ITEM_LEVEL.SIGUNGU }).then((result: any) => {
        if (Array.isArray(result) && result[0]) {
          const avgLatitude = result.reduce((sum, r) => sum + r.latitude, 0) / result.length;
          const avgLongitude = result.reduce((sum, r) => sum + r.longitude, 0) / result.length;

          NaverMapObject.instance?.setZoom(getZoomBySido(sido as string));
          NaverMapObject.instance?.morph(new naver.maps.LatLng(avgLatitude, avgLongitude));
        }

        const params: any = {};
        if (typeof sido === "string" && sido !== "") params.ccbaCtcdNm = sido;
        if (typeof sigungu === "string" && sigungu !== "") params.ccsiName = sigungu;
        setIsOrder(true);
        BottomSheetSearch.items({ ...params, ...getMapCenter(), orderBy }).then(setBottomSheetItems);
      });

      setMode(0);
      dispatch(changeAppMode({ mode: APP_MODES.FILTER_SEARCH }));
      const title = sigungu ? `지역별 검색 > ${sido} > ${sigungu}` : `지역별 검색 > ${sido}`;
      navigate("/search_result", { state: { title } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (sido === null) {
      setSigunguList([]);
    } else {
      AppSearch.region.sigungu(sido).then((data) => {
        setSigunguList(data);
      });
    }
  }, [sido]);

  const getZoomBySido = (sido: string) => {
    // "서울특별시",  "경상남도",  "전라남도",  "세종특별자치시",  "충청남도",  "충청북도",  "강원도",  "경상북도",  "경기도",  "인천광역시",  "대전광역시",  "제주특별자치도",  "부산광역시",  "전라북도",  "대구광역시",  "광주광역시",  "기타",  "울산광역시"
    switch (sido) {
      case "부산광역시":
      case "울산광역시":
        return 10;

      case "충청북도":
      case "경기도":
      case "전라북도":
        return 9;

      case "경상남도":
      case "충청남도":
      case "강원도":
      case "경상북도":
      case "전라남도":
        return 8;

      case "인천광역시":
        return 7;

      default:
        return 11; // 서울, 대전, 제주도, 대구, 광주
    }
  };

  const SelectCol = ({ text, defaultText, thisMode }: any) => {
    let clazz = mode === thisMode ? " text-rouge" : "text-brown-grey";
    return (
      <Col
        className={`px-3 py-2 border text-center fw-bold ${clazz}`}
        onClick={() => {
          if (thisMode === 1 && !sido) return;
          setMode(thisMode);
        }}
      >
        {text ?? defaultText}
      </Col>
    );
  };

  const ItemRow = (): JSX.Element => {
    if (!sido || mode === 0) {
      return (
        <Row xs={3} sm={5} md={6} id="app_region_filter_items">
          {SIDOS.map(
            (name, i) =>
              name !== "기타" && (
                <Col
                  className={"p-3 border text-center " + (name === sido ? "selected" : "")}
                  key={i}
                  onClick={() => {
                    setSido(name);
                    setSigunguList([]);
                    setMode(1);
                  }}
                >
                  {name}
                </Col>
              )
          )}
        </Row>
      );
    } else {
      return (
        <Row xs={3} sm={5} md={6} id="app_region_filter_items">
          <Col
            className={"p-3 border text-center"}
            onClick={() => {
              setSigungu("");
              setMode(2);
            }}
          >
            전체
          </Col>

          {sigunguList.map((name, i) => (
            <Col
              className={"p-3 border text-center"}
              key={i}
              onClick={() => {
                setSigungu(name);
                setMode(2);
              }}
            >
              {name}
            </Col>
          ))}
        </Row>
      );
    }
  };

  return (
    <div style={{ width: "100vw", backgroundColor: "#FFF" }} id="app_region_filter">
      <Row xs={2}>
        <SelectCol text={sido} defaultText="시/도" thisMode={0} />
        <SelectCol text={sigungu} defaultText="시/군/구" thisMode={1} />
      </Row>
      <ItemRow />
    </div>
  );
};

export default RegionFilter;
