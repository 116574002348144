import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Api } from "lib/axios/Api";
import { USER_STATUS_TYPES } from "constants/CONSTANTS";
import MyToast from "components/modules/MyToast/MyToast";
import { User } from "model/User";
import { Review } from "model/Review";

interface commonSaveProps {
  send: Function;
  title?: String;
}
export function commonSave(props: commonSaveProps) {
  Swal.fire({
    title: props.title || "저장하시겠습니까?",
    showDenyButton: true,
    confirmButtonText: "저장",
    denyButtonText: "취소",
  }).then((result) => {
    if (result.isConfirmed) {
      let id = toast.loading("저장 중입니다.");
      props
        .send()
        .then(() => {
          toast.success("저장되었습니다.", {
            autoClose: 500,
            pauseOnFocusLoss: false,
          });
        })
        .catch((err: any) => {
          toast.error("저장에 실패하였습니다.", {
            autoClose: 1000,
            pauseOnFocusLoss: false,
          });
        })
        .finally(() => {
          toast.dismiss(id);
        });
    }
  });
}

interface commonSwalConfirmProps {
  title: string;
  confirmButtonText: string;
}
export const commonSwalConfirm = async (props: commonSwalConfirmProps) => {
  return await Swal.fire({
    ...props,
    showDenyButton: true,
    denyButtonText: "취소",
  });
};

interface animationByRefProps {
  refObject: React.RefObject<any>;
  effect: any[];
  option?: {
    duration: number;
    easing?: string;
    iterations?: number;
  };
  after?: Function;
}
export function animationByRef({
  refObject,
  effect,
  option = { duration: 300, easing: "ease-out", iterations: 1 },
  after,
}: animationByRefProps) {
  const ref = refObject.current;
  if (!ref) return;

  let animation = ref.animate(effect, option);
  animation.onfinish = () => {
    effect.forEach((json) => {
      for (var key in json) {
        ref.style.setProperty(key, json[key]);
      }
    });
    if (after && typeof after === "function") after();
    animation.cancel();
  };
}

/**
 * JSX.Element를 HTML String으로 변환
 * @param element String으로 변환 시킬 Element
 * @returns
 */
export function elementToString(element: JSX.Element) {
  const cEl = document.createElement(element.type);

  if (element.props?.className) {
    cEl.classList = element.props.className;
  }

  if (Array.isArray(element.props?.children)) {
    element.props.children.forEach((children: JSX.Element) => {
      cEl.appendChild(elementToString(children));
    });
  } else if (element.props?.children) {
    cEl.innerHTML = element.props.children;
  }

  return cEl;
}

interface ToastProps {
  msg: string;
}
export function appErrorMsg(props: ToastProps) {
  // toast.error(props.msg, { autoClose: 1000, pauseOnFocusLoss: false });
  MyToast.success({ text: props.msg });
}

export function appSuccessMsg(props: ToastProps) {
  // toast.success(props.msg, { autoClose: 500, pauseOnFocusLoss: false });
  MyToast.success({ text: props.msg });
}

export function appDebugMsg(props: ToastProps) {
  MyToast.success({ text: props.msg });
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isUserLogin = () => {
  return localStorage.getItem("user_tokens") !== null;
};

export const isIos = () => {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  return varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1;
};

export const existsUserInfo = (e: any) => {
  return e.response.data.code !== "L001";
};

export const checkScrollLength = (length: number) => Math.abs(length) < 5;

export const isBlackList = async (userStatus: number | null = null) => {
  if (userStatus === null) userStatus = await Api.get("app/auth/status").then((result) => result.data);
  return userStatus === USER_STATUS_TYPES.BLACKLIST;
};

export const hasReviewImage = (review: Review): boolean => {
  const reviewDetail = review.reviewDetails[0];
  return (
    reviewDetail.reviewImageUrl1 != null || reviewDetail.reviewImageUrl2 != null || reviewDetail.reviewImageUrl3 != null
  );
};

export const getReviewImageArray = (review: Review): string[] => {
  const reviewDetail = review.reviewDetails[0];
  return [reviewDetail.reviewImageUrl1, reviewDetail.reviewImageUrl2, reviewDetail.reviewImageUrl3].filter(
    (url) => url
  ) as string[];
};

export const getUserNickName = (user: User | null) => {
  return user ? user.nickName : "비사용자";
};

export const nickNameCheck = async (nickName: string) => {
  if (!nickName) {
    appErrorMsg({ msg: "닉네임이 입력되지 않았습니다." });
    return false;
  } else if (nickName.length < 2) {
    appErrorMsg({ msg: "닉네임은 2자 이상 입력해주세요." });
    return false;
  } else if (!/^[a-zA-Z\d가-힣]+$/.test(nickName)) {
    appErrorMsg({ msg: "닉네임은 한글, 영어, 숫자만 입력 가능합니다." });
    return false;
  }

  const length = nickName.length + nickName.replace(/[a-zA-z\d]/g, "").length;

  if (length > 20) {
    appErrorMsg({ msg: "닉네임은 한글 10자 이하, 영문 및 숫자 20자 이하로 혼합하여 입력 가능합니다." });
    return false;
  }

  var params = new URLSearchParams();
  params.append("nickName", nickName);

  const result = await Api.post("/app/auth/check_nick_name", params);
  if (result.data) {
    return true;
  } else {
    appErrorMsg({ msg: "이미 사용중인 닉네임입니다." });
    return false;
  }
};

export const convertGender = (gender: number) => {
  switch (gender) {
    case 1:
      return "남자";
    case 2:
      return "여자";
    default:
      return "비공개";
  }
};
