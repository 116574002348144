import NaverLoginBtnImage from "assets/images/sns/naver_login_btn.png";
import { appErrorMsg, appSuccessMsg, existsUserInfo } from "assets/js/common";
import { userLogin } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}&state=`;

export const NaverLoginButton = ({ deleted = false }: { deleted?: boolean }) => {
  if (deleted) {
    localStorage.setItem(
      "delete_auth",
      JSON.stringify({
        date: new Date(),
      })
    );
  }

  const state = Math.random().toString(36);

  return (
    <div style={{ backgroundColor: "#03C75A" }}>
      <a href={NAVER_AUTH_URL + state} className="w-100">
        <Image src={NaverLoginBtnImage} />
      </a>
    </div>
  );
};

const NaverLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("code", searchParams.get("code") as string);
    params.append("state", searchParams.get("state") as string);
    Api.post("/app/naver/token", params)
      .then((response) => {
        const delete_auth = localStorage.getItem("delete_auth");
        localStorage.removeItem("delete_auth");
        appSuccessMsg({ msg: "로그인되었습니다." });
        dispatch(userLogin({ userToken: response.data }));

        // 탈퇴 페이지
        if (delete_auth) {
          navigate("/sign/delete");

          // 로그인 성공
        } else {
          navigate("/");
        }
      })
      .catch((e) => {
        const delete_auth = localStorage.getItem("delete_auth");
        localStorage.removeItem("delete_auth");

        // 탈퇴 페이지
        if (delete_auth) {
          appErrorMsg({ msg: "가입되어 있지 않은 계정입니다." });
          navigate("/sign/delete");

          // 미가입 회원
        } else if (!existsUserInfo(e)) {
          navigate("/sign", { state: e.response.data.body });

          // 에러
        } else {
          navigate("/setting/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default NaverLogin;
