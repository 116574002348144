import { CulturalAsset } from "model/CulturalAsset";
import { useRef } from "react";
import { Col, Image, Row, Table } from "react-bootstrap";

interface Props {
  cul: CulturalAsset;
}
const DetailHome = ({ cul }: Props) => {
  return (
    <Row className="contens_wrap" xs={1}>
      <Col id="detail_cul_home">
        <div className="image-wrap">
          <Image src={cul.imageUrl.replace("http://", "https://")} id="app_detail_main_image" className="mb-1" />
          <div className="text-end text-brown-grey fs-7 me-2 fw-r">이미지 출처 : 문화재 청</div>
        </div>
      </Col>

      <Col className="app-detail-info-table">
        <Table>
          <colgroup>
            <col width="130px"></col>
          </colgroup>
          <tbody>
            <tr>
              <td>종목/지정번호</td>
              <td>{`${cul.ccmaName ?? "-"} / ${cul.ccbaAsno ?? "-"}`}</td>
            </tr>
            <tr>
              <td>시대</td>
              <td>{cul.ccceName ?? "-"}</td>
            </tr>
            <tr>
              <td>분류</td>
              <td>{cul.gcodeName ?? "-"}</td>
            </tr>
            <tr>
              <td>관리자(관리단체)</td>
              <td className="pt-3">{cul.ccbaAdmin ?? "-"}</td>
            </tr>
            <tr>
              <td>내용 출처</td>
              <td>문화재청</td>
            </tr>
          </tbody>
        </Table>
      </Col>

      <DetailContent cul={cul} />
    </Row>
  );
};

interface DetailContentProps {
  cul: CulturalAsset;
}

const DetailContent = (props: DetailContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Col className="p-3 pt-1 w-100 text-break border-bottom">
      <div className="text-center mb-2">문화재 상세 설명</div>
      <div className={`detail-content-wrap full fw-r fs-6`} ref={contentRef}>
        {props.cul.content}
      </div>
    </Col>
  );
};

export default DetailHome;
