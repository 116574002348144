import { Review } from "model/Review";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ReviewByNomal from "./ReviewByNomal";

interface ReviewByNomalProps {
  review: Review;
  setReviews: React.Dispatch<React.SetStateAction<Review[]>>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}
const ReviewByDeclaration = (props: ReviewByNomalProps) => {
  const [isView, setIsView] = useState(false);

  return (
    <Row className="flex-column m-0 border-bottom">
      <Col>
        <Row className="py-3 m-0">
          <Col className="text-brown-grey fw-r fs-7 pt-1">신고 처리된 리뷰입니다.</Col>
          <Col className="col-auto px-1">
            <Button
              className="fs-7 me-2 bg-white rounded-pill border-brown-grey text-brown-grey py-1 fw-r"
              variant="outline-brown-grey"
              onClick={() => setIsView((view) => !view)}
            >
              {isView ? "접기" : "보기"}
            </Button>
          </Col>
        </Row>
        <Row className="p-2 m-0 mb-2">
          {isView && (
            <Col className="border p-0 bg-gray">
              <ReviewByNomal {...props} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ReviewByDeclaration;
