import { CSSProperties } from "react";
import { Button } from "react-bootstrap";

const counterStyle: CSSProperties = {
  position: "absolute",
  top: "-.4rem",
  right: "-.7rem",
  background: "Red",
  fontSize: ".7rem",
  borderRadius: "50%",
  width: "1.2rem",
  height: "1.2rem",
  lineHeight: "1.3rem",
  textAlign: "center",
  color: "#FFF",
};

const QuestionBtn = ({ cnt }: { cnt: number }) => {
  return (
    <div style={{ position: "relative" }}>
      <Button className="btn-sm" variant="dark-slate-blue">
        기출문제
      </Button>
      <span style={counterStyle} className="bg-rouge">
        {cnt}
      </span>
    </div>
  );
};

export default QuestionBtn;
