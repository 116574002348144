import { Button, Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { User } from "model/User";
import { Api } from "lib/axios/Api";
import { appErrorMsg, convertGender, isUserLogin } from "assets/js/common";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import iconMyBtn from "assets/images/icon/icon_modify_my_btn.png";

interface Props {
  isMyPageBtn?: boolean;
  isLogOutBtn?: boolean;
}

const MyContent = (props: Props) => {
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLogin())
      Api.get("app/auth/my")
        .then((response) => setUser(response.data))
        .catch(() => {
          appErrorMsg({ msg: "서버에 접속할 수 없습니다." });
        });
  }, [navigate]);

  const getDate = () => {
    if (!user) return "";
    return user.createdAt.slice(0, 10);
  };

  const logout = () => {
    MyConfirm({
      text: "로그아웃하시겠습니까?",
      onOkClick: () => {
        localStorage.removeItem("user_tokens");
        window.location.reload();
      },
    });
  };

  if (isUserLogin() && user) {
    return (
      <Row id="my_wrap" className="m-2 mb-3 border flex-nowrap">
        <Col>
          <Row className="py-3 px-2 pb-2 flex-column">
            <Col className="text-dark-slate-blue mb-1">
              {user?.nickName} ({convertGender(user?.gender)})
              <Link to="/setting/mypage/modify">
                <Image src={iconMyBtn} className="ms-5" width={16} />
              </Link>
            </Col>
            <Col className="fs-7 fw-r mb-1">{user?.email}</Col>
            <Row className="me-0 pe-0">
              <Col className="fs-7 fw-r text-brown-grey w-100">{getDate()} 가입</Col>
              {props.isMyPageBtn && (
                <Col className="p-0" xs="auto">
                  <Link to="/setting/mypage">
                    <Button className="rounded-pill fs-7 px-3 " variant="outline-very-light-pink" size="sm">
                      마이 페이지
                    </Button>
                  </Link>
                </Col>
              )}
              {props.isLogOutBtn && (
                <Col className="p-0" xs="auto">
                  <Button
                    className="rounded-pill fs-7 px-4"
                    variant="outline-very-light-pink"
                    size="sm"
                    onClick={logout}
                  >
                    로그아웃
                  </Button>
                </Col>
              )}
            </Row>
          </Row>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row id="my_wrap" className="m-2 mb-3 border border-very-light-pink">
        <Row className="m-0 p-3 flex-column">
          <Col className="p-0 pt-2 text-center text-brown-grey">서비스 이용을 위해 로그인이 필요합니다.</Col>
          <Col className="text-center">
            <Link to="/setting/login">
              <Button variant="outline-very-light-pink" size="sm" className="rounded-pill fs-7 px-3">
                로그인
              </Button>
            </Link>
          </Col>
        </Row>
      </Row>
    );
  }
};

export default MyContent;
