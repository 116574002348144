import { appErrorMsg, appSuccessMsg, validateEmail } from "assets/js/common";
import AppModal from "components/layouts/AppModal";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";
import { userLogin } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { CSSProperties, useState, useEffect, useRef } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface SignData {
  kakaoId?: string;
  nickName: string;
  email: string;
  gender: number;
}

const linkStyle: CSSProperties = {
  color: "blue",
  textDecoration: "underline",
};

interface CustomizedState {
  kakaoId?: string;
  email?: string;
  gender?: number;
}

const SignUp = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const termRefs = useRef<HTMLInputElement[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [terms, setTerms] = useState<any[]>([]);
  const [validated, setValidated] = useState(false);
  const [nickNameCheck, setNickNameCheck] = useState<boolean>(false);
  const [emailLeft, setEmailLeft] = useState<string>(state?.email?.split("@")[0] ?? "");
  const [emailRight, setEmailRight] = useState<string>(state?.email?.split("@")[1] ?? "");
  const [signData, setSignData] = useState<Partial<SignData>>({ nickName: "", ...state });

  useEffect(() => {
    Api.get("/app/term/columns").then((response) => setTerms(response.data));
  }, []);

  const submitHandler = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const email = [emailLeft.trim(), emailRight.trim()].join("@");

    let termData: any[] = [];
    termRefs.current.forEach((input: any, i: number) => {
      termData.push({
        termIdx: terms[i].term.idx,
        columnIdx: terms[i].column.idx,
        checked: Number(input.checked),
      });
    });

    if (!validateEmail(email)) return appErrorMsg({ msg: "올바르지 않은 이메일입니다." });
    if (!nickNameCheck) return appErrorMsg({ msg: "닉네임 중복확인이 필요합니다." });

    if (e.target.checkValidity()) {
      const params = { ...signData, email, terms: termData };

      MyConfirm({
        text: "가입하시겠습니까?",
        okBtnText: "가입",
        onOkClick: () => {
          Api.post(`/app/auth/sign`, params).then((response) => {
            appSuccessMsg({ msg: "가입되었습니다." });
            dispatch(userLogin({ userToken: response.data }));
            navigate("/");
          });
        },
      });
    }
    setValidated(true);
  };

  const checkNickName = async () => {
    const nickName = signData.nickName?.trim();
    if (!nickName) {
      return appErrorMsg({ msg: "닉네임이 입력되지 않았습니다." });
    } else if (nickName.length < 2) {
      return appErrorMsg({ msg: "닉네임은 2자 이상 입력해주세요." });
    } else if (!/^[a-zA-Z\d가-힣]+$/.test(nickName)) {
      return appErrorMsg({ msg: "닉네임은 한글, 영어, 숫자만 입력 가능합니다." });
    }

    const length = nickName.length + nickName.replace(/[a-zA-z\d]/g, "").length;

    if (length > 20) {
      return appErrorMsg({ msg: "닉네임은 한글 10자 이하, 영문 및 숫자 20자 이하로 혼합하여 입력 가능합니다." });
    }

    var params = new URLSearchParams();
    params.append("nickName", nickName);

    const result = await Api.post("/app/auth/check_nick_name", params);
    if (result.data) {
      setNickNameCheck(true);
      appSuccessMsg({ msg: "사용 가능한 닉네임입니다." });
    } else {
      appErrorMsg({ msg: "이미 사용중인 닉네임입니다." });
    }
  };

  const updateModel = (fieldsToUpdate: any) => {
    if (signData) setSignData({ ...signData, ...fieldsToUpdate });
  };

  const Term = ({ data, index }: any) => {
    const navigate = useNavigate();
    const required = data.term.required === 1 ? true : false;

    return (
      <div>
        <Row className="flex-nowrap mb-3">
          <Col>
            <span
              style={linkStyle}
              onClick={() => navigate(`term/${data.term.idx}`)}
              className="text-dark-slate-blue fw-r"
            >
              {`${data.term.title} (${required ? "필수" : "선택"})`}
            </span>
          </Col>
          <Col className="col-auto">
            <Form.Check
              required={required}
              ref={(e: any) => (termRefs.current[index] = e)}
              defaultChecked={termRefs.current[index]?.checked ?? false}
              color="dark-slate-blue"
            />
          </Col>
        </Row>
      </div>
    );
  };

  const termAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    termRefs.current.forEach((term) => {
      term.checked = e.target.checked;
    });
  };

  return (
    <AppModal title="추가 정보 입력" backClickHandler={() => navigate("/")}>
      <Form validated={validated} onSubmit={submitHandler}>
        <div className="h-100 w-100 overflow-y-auto p-3 d-flex flex-column">
          <Row className="mb-3 fw-bold">
            <Col className="text-brown-grey">이메일</Col>
          </Row>
          <Row className="flex-nowrap mb-5">
            <InputGroup as={Col} className="w-100 flex-fill pe-0">
              <Form.Control
                defaultValue={emailLeft}
                required
                onChange={(e) => setEmailLeft(e.target.value)}
                disabled={emailLeft !== ""}
              />
            </InputGroup>
            <Col className="w-auto flex-shrink-1 p-1 pt-3">@</Col>
            <InputGroup as={Col} className="flex-fill ps-0" style={{ width: "15rem" }}>
              <Form.Control
                defaultValue={emailRight}
                required
                onChange={(e) => setEmailRight(e.target.value)}
                disabled={emailRight !== ""}
              />
            </InputGroup>
          </Row>

          <Row className="mb-3 fw-bold">
            <Col className="text-brown-grey">닉네임</Col>
          </Row>
          <Row className="flex-nowrap mb-5">
            <InputGroup as={Col} className="w-100 flex-fill">
              <Form.Control
                value={signData.nickName}
                onChange={(e) => {
                  setNickNameCheck(false);
                  updateModel({ nickName: e.target.value });
                }}
                required
                minLength={2}
                maxLength={20}
              />
            </InputGroup>
            <Col className="text-nowrap">
              <Button onClick={checkNickName} disabled={nickNameCheck} variant="dark-slate-blue">
                중복 확인
              </Button>
            </Col>
          </Row>

          <Row className="mb-1 fw-bold">
            <Col className="text-brown-grey" xs="auto">
              성별
            </Col>
            <Col className="ms-auto" xs="auto">
              <Row className="flex-nowrap">
                <InputGroup as={Col} className="w-100 flex-fill flex-nowrap">
                  <Form.Check
                    type="radio"
                    name="gender"
                    defaultChecked={true}
                    onChange={() => updateModel({ gender: 0 })}
                    required
                    color="dark-slate-blue"
                    className="me-3"
                  />
                  <Form.Label className="text-nowrap">비공개</Form.Label>
                </InputGroup>
                <InputGroup as={Col} className="w-100 flex-fill">
                  <Form.Check
                    type="radio"
                    name="gender"
                    defaultChecked={true}
                    onChange={() => updateModel({ gender: 1 })}
                    required
                    color="dark-slate-blue"
                    className="me-3"
                  />
                  <Form.Label>남자</Form.Label>
                </InputGroup>
                <InputGroup as={Col} className="w-100 flex-fill">
                  <Form.Check
                    type="radio"
                    name="gender"
                    onChange={() => updateModel({ gender: 2 })}
                    required
                    color="dark-slate-blue"
                    className="me-3"
                  />
                  <Form.Label>여자</Form.Label>
                </InputGroup>
              </Row>
            </Col>
          </Row>

          <hr className="bg-brown-grey mb-4" />

          <Row className="mb-5 fs-5 fw-bold">
            <Col>가입 약관 동의</Col>
          </Row>
          <Row className="flex-nowrap mb-3">
            <Col>
              <b>모든 약관에 동의합니다.</b>
            </Col>
            <Col className="col-auto">
              <Form.Check onChange={termAllChange} color="dark-slate-blue" />
            </Col>
          </Row>
          {terms.map((term, i) => (
            <Term data={term} index={i} key={i} />
          ))}

          <hr className="bg-brown-grey mb-4" />

          <Row className="mt-auto">
            <Col>
              <Button className="w-100" type="submit" variant="dark-slate-blue">
                가입하기
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </AppModal>
  );
};

export default SignUp;
