import { Api } from "lib/axios/Api";
import { Notice } from "model/Notice";
import { useEffect, useState } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";

const SettingNotice = () => {
  const [notices, setNotices] = useState<Notice[]>([]);

  useEffect(() => {
    Api.get("app/notices").then((response) => {
      setNotices(response.data);
    });
  }, []);

  return (
    <ListGroup>
      {notices.map((notice) => (
        <NoticeElement key={notice.idx} notice={notice} />
      ))}
    </ListGroup>
  );
};

interface NoticeElementProps {
  notice: Notice;
}
const NoticeElement = ({ notice }: NoticeElementProps) => {
  const [visibled, setVisibled] = useState(false);

  return (
    <ListGroupItem className="dropdown">
      <Row className="flex-column m-0 text-dark-slate-blue p-1">
        <Col className="p-0">
          <Row>
            <Col>{notice.title}</Col>
            <Col className="col-auto" onClick={() => setVisibled(() => !visibled)}>
              {visibled ? "∧" : "∨"}
            </Col>
          </Row>
        </Col>
        <Col className={`p-0 fs-7 fw-r content ${visibled ? "mt-3" : "hidden"}`}>
          <div dangerouslySetInnerHTML={{ __html: notice.text }}></div>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default SettingNotice;
