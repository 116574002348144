import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import NaverMap from "../components/NaverMap";
import SearchPage from "../components/filters/SearchPage";
import AppBottom from "../components/modules/AppBottom";
import Favorite from "../components/modules/Favorite";
import AppSetting from "./AppSetting/AppSetting";
import DeleteAccount from "./AppSetting/DeleteAccount";
import InquiryCreate from "./AppSetting/Inquiry/InquiryCreate";
import InquiryList from "./AppSetting/Inquiry/InquiryList";
import InquiryView from "./AppSetting/Inquiry/InquiryView";
import MyDeclaration from "./AppSetting/MyPage/MyDeclaration";
import MyModify from "./AppSetting/MyPage/MyModify";
import MyPage from "./AppSetting/MyPage/MyPage";
import MyReview from "./AppSetting/MyPage/MyReview";
import Service from "./AppSetting/Service/Service";
import SettingBlindList from "./AppSetting/SettingBlindList";
import SettingPermission from "./AppSetting/SettingPermission";
import SettingTerm from "./AppSetting/SettingTerm";
import AppLogin from "./Auth/AppLogin";
import CreateReview from "./Detail/CreateReview";
import DetailCul from "./Detail/DetailCul";
import Home from "./Home";
import QuestionView from "./Question/QuestionView";

const Main = () => {
  return (
    <Container fluid className="w-100 h-100 p-0 inline-flex">
      <div id="map_wrap" className="d-flex flex-column">
        <NaverMap />
        <AppBottom />
      </div>
      <AppHeader />

      <div id="page_wrap">
        <Routes>
          <Route index element={<Home />} />

          {/* 검색 */}
          <Route path="search" element={<SearchPage />} />

          {/* 즐겨찾기 */}
          <Route path="favorite" element={<Favorite />} />

          {/* 상세페이지 */}
          <Route path="detail/:idx/*" element={<DetailCul />} />

          <Route path="detail/:idx/review/:reviewIdx" element={<CreateReview />} />
          <Route path="detail/:idx/review" element={<CreateReview />} />

          {/* 설정 */}
          <Route path="setting">
            <Route index element={<AppSetting />} />
            <Route path="mypage">
              <Route index element={<MyPage />} />
              <Route path="review" element={<MyReview />} />
              <Route path="declaration" element={<MyDeclaration />} />
              <Route path="modify" element={<MyModify />} />
            </Route>

            {/* 설정 > 고객센터 */}
            <Route path="service" element={<Service />} />

            {/* 1:1 문의 */}
            <Route path="inquiry">
              <Route index element={<InquiryList />} />
              <Route path="create" element={<InquiryCreate />} />
              <Route path=":idx" element={<InquiryView />} />
            </Route>
            <Route path="permission" element={<SettingPermission />} />
            <Route path="login" element={<AppLogin />} />
            <Route path="term/:idx" element={<SettingTerm />} />
            <Route path="blind_list" element={<SettingBlindList />} />
            <Route path="delete_account" element={<DeleteAccount />} />
          </Route>

          <Route path="question/:idx" element={<QuestionView />} />
        </Routes>
      </div>
    </Container>
  );
};
export default Main;
