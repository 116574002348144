import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const APP_MODES = {
  DEFULAT: 0,
  BOTTOM_SHEET: 1,
  FILTER_REGION: 2,
  FILTER_AGE: 3,
  FILTER_QUESTION: 4,

  FILTER_SEARCH: 10,
};

const convertUserIdx = (auth?: string | null) => {
  if (auth === undefined) {
    let tokens = localStorage.getItem("user_tokens");
    if (tokens) {
      auth = JSON.parse(tokens).accessToken;
    }
  }
  try {
    if (auth) return JSON.parse(atob(auth.split(".")[1])).user.idx;
  } catch (error) {}

  return null;
};

export const app = createSlice({
  name: "app",
  initialState: {
    mode: 0,
    auth: {
      user: {
        idx: convertUserIdx(),
      },
    },
  },
  reducers: {
    changeAppMode: (state: any, { payload }: PayloadAction<{ mode: number }>) => {
      state.mode = payload.mode;
    },
    toggleAppMode: (state: any, { payload }: PayloadAction<{ mode: number }>) => {
      state.mode = state.mode === payload.mode ? APP_MODES.DEFULAT : payload.mode;
    },
    mapIdle: (state: any) => {
      if (state.mode !== APP_MODES.FILTER_SEARCH) state.mode = APP_MODES.DEFULAT;
    },
    userLogin: (
      state: any,
      {
        payload,
      }: PayloadAction<{
        userToken: { accessToken: string; refreshToken: string };
      }>
    ) => {
      localStorage.setItem("user_tokens", JSON.stringify(payload.userToken));
      state.auth.user.idx = convertUserIdx();
    },
    userLogout: (state: any) => {
      state.auth.user.idx = null;
      localStorage.removeItem("user_tokens");
    },
  },
});

export const { changeAppMode, toggleAppMode, mapIdle, userLogin, userLogout } = app.actions;

export default app.reducer;
