import { appErrorMsg } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { Review, ReviewDetail } from "model/Review";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import IconCamera from "assets/images/icon/icon_camera.png";
import IconClosePill from "assets/images/icon/icon_close_pill.png";
import MyConfirm from "components/modules/MyConfirm/MyConfirm";

const TextAreaStyle: CSSProperties = {
  backgroundColor: "transparent",
  outline: "none",
  boxShadow: "none",
  borderRadius: "0",
};

const ImageStyle: CSSProperties = {
  height: "80px",
  width: "80px",
};

const newReviewDetail = () => {
  return {
    text: "",
    isLast: 1,
    status: 1,
    reviewImageUrl1: null,
    reviewImageUrl2: null,
    reviewImageUrl3: null,
  };
};

const CreateReview = () => {
  const { reviewIdx, idx } = useParams() as { reviewIdx?: string; idx: string };
  const navigate = useNavigate();
  const inputFile = useRef<HTMLInputElement>(null);
  const [review, setReview] = useState<Partial<Review>>({ culturalAssetIdx: parseInt(idx) });

  useEffect(() => {
    if (reviewIdx) {
      Api.get(`app/reviews/${reviewIdx}`).then((response) => {
        setReview(response.data);
      });
    }
  }, [reviewIdx]);

  const backClickHandler = () => {
    navigate(-1);
  };

  // 이미지 첨부
  const imgAddHandler = (e: any) => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = imgChangeHandler;
    input.accept = "image/png, image/jpeg";
    input.click();
  };

  const imgChangeHandler = (e: any) => {
    const formData = new FormData();
    if (!review.reviewDetails?.length) review.reviewDetails = [newReviewDetail()];
    if (e.target.files[0].size > 2097152) {
      appErrorMsg({ msg: "이미지는 2MB를 초과할 수 없습니다." });
      return;
    }
    formData.append("upload", e.target.files[0]);
    formData.append("uri", `/${idx}/review`);

    const reviewDetail = review.reviewDetails[0];
    if (reviewDetail.reviewImageUrl1 && reviewDetail.reviewImageUrl2 && reviewDetail.reviewImageUrl3) {
      return appErrorMsg({ msg: "이미지는 최대 3장까지 등록 가능합니다." });
    }
    Api.post(`/app/images`, formData).then((response) => {
      if (!reviewDetail.reviewImageUrl1) {
        reviewDetail.reviewImageUrl1 = response.data.url;
      } else if (!reviewDetail.reviewImageUrl2) {
        reviewDetail.reviewImageUrl2 = response.data.url;
      } else if (!reviewDetail.reviewImageUrl3) {
        reviewDetail.reviewImageUrl3 = response.data.url;
      }
      updateModel({ reviewDetails: [reviewDetail] });
    });
  };

  const updateModel = (fieldsToUpdate: Partial<Review>) => {
    if (review) setReview({ ...review, ...fieldsToUpdate });
  };

  const updateDetailModel = (fieldsToUpdate: Partial<ReviewDetail>) => {
    if (review) {
      const reviewDetail = { ...getReviewDetail(), ...fieldsToUpdate };
      setReview({ ...review, reviewDetails: [reviewDetail] });
    }
  };

  const submitHanlder = async () => {
    const reviewDetail = getReviewDetail();

    if (reviewDetail.text === "") {
      appErrorMsg({ msg: "내용이 입력되지 않았습니다." });
    } else {
      MyConfirm({
        text: "작성하시겠습니까?",
        okBtnText: "작성",
        onOkClick: () => {
          if (reviewIdx) {
            Api.put(`app/reviews`, review).then((response) => {
              if (response.status === 200) {
                navigate(-1);
              }
            });
          } else {
            Api.post(`app/reviews/${idx}`, review).then((response) => {
              if (response.status === 200) {
                navigate(-1);
              }
            });
          }
        },
      });
    }
  };

  const getReviewDetail = (): ReviewDetail => {
    if (!review.reviewDetails?.length) {
      return newReviewDetail();
    } else {
      return review.reviewDetails[0];
    }
  };

  const getImage = (index: number) => {
    const reviewDetail = getReviewDetail();
    if (index === 0) return reviewDetail.reviewImageUrl1 ?? IconCamera;
    else if (index === 1) return reviewDetail.reviewImageUrl2 ?? IconCamera;
    else if (index === 2) return reviewDetail.reviewImageUrl3 ?? IconCamera;
    return IconCamera;
  };

  const PhotoComponent = ({ index }: { index: number }) => {
    const image = getImage(index);
    const deleteImage = (index: number) => {
      switch (index) {
        case 0:
          return updateDetailModel({ reviewImageUrl1: null });
        case 1:
          return updateDetailModel({ reviewImageUrl2: null });
        case 2:
          return updateDetailModel({ reviewImageUrl3: null });
      }
    };

    if (image === IconCamera) {
      return (
        <Col
          className="bg-very-light-pink p-0 mx-1"
          style={{ position: "relative", width: "80px", height: "80px" }}
          xs="auto"
          onClick={imgAddHandler}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Image src={getImage(index)} style={{ width: "30px" }} />
            <div className="text-white fw-r fs-7 text-truncate">사진추가</div>
          </div>
        </Col>
      );
    } else {
      return (
        <Col className="p-0 mx-1 border" style={{ position: "relative" }} xs="auto">
          <Image src={getImage(index)} style={ImageStyle} />
          <Image
            src={IconClosePill}
            style={{
              position: "absolute",
              width: "20px",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={() => deleteImage(index)}
          />
        </Col>
      );
    }
  };

  return (
    <div id="app_modal_wrap" className="d-flex flex-column" style={{ right: `0px` }}>
      <Row className="m-1">
        <div style={{ display: "contents" }} className="content-link" onClick={backClickHandler}>
          <span className="arrow-prev ms-1"></span>
        </div>
        <Col className="w-100 text-center mt-2">리뷰쓰기</Col>
      </Row>
      <hr className="my-2 bg-brown-grey" />
      <div className="h-100 w-100 overflow-auto p-3">
        <Row className="flex-column m-0 h-100">
          <Col className="text-center mb-3">
            <input
              type="file"
              ref={inputFile}
              style={{ display: "none" }}
              accept="image/png, image/jpeg"
              onChange={(e) => imgChangeHandler(e)}
            />
            <Row className="px-2">
              <PhotoComponent index={0} />
              <PhotoComponent index={1} />
              <PhotoComponent index={2} />
              <Col className="p-0" xs="auto">
                <Row className="m-0 align-items-end h-100">
                  <Col className="fs-8 fw-r text-brown-grey text-nowrap" xs="auto">
                    (최대 3장)
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col style={{ flex: 100 }} className="mb-5">
            <Form.Control
              as="textarea"
              className="h-100 noresize border-brown-grey mb-2 fw-r"
              style={TextAreaStyle}
              placeholder="리뷰 내용"
              defaultValue={getReviewDetail().text}
              onChange={(e) => updateDetailModel({ text: e.target.value })}
              maxLength={400}
            />
            <div className="text-end fs-7">
              {getReviewDetail().text?.length}
              <span className="text-muted">/400</span>
            </div>
          </Col>
          <Col>
            <Button onClick={submitHanlder} className="w-100 p-2" variant="dark-slate-blue">
              작성하기
            </Button>
          </Col>
          <Col>
            <div className="bg-gray text-brown-grey p-3 mt-3 text-break fs-7 fw-r">
              리뷰 작성 시 유의 사항
              <br />
              <br />
              역지사지는 <span className="fw-bold text-danger">리뷰 운영정책</span>에 위배되는 게시물이 확인된 경우
              <br />
              확인 결과에 따라 게시물이 블라인드 & 경고 조치 또는 게시물 이용이 제한이 될 수 있습니다.
              <br />
              서비스 이용 제한의 조건, 세부 내용 등은 &nbsp;
              <Link to="/setting/term/5" className="text-decoration-none fw-bold text-primary">
                리뷰 운영정책
              </Link>
              을 참고하시기 바랍니다.
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CreateReview;
