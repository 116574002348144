import { appErrorMsg, appSuccessMsg, isUserLogin } from "assets/js/common";
import { Api } from "lib/axios/Api";
import { CulturalAsset } from "model/CulturalAsset";
import { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { NaverMapObject } from "components/NaverMap";
import IconQuestion from "assets/images/icon/icon_detail_question.png";
import IconQuestionDisabled from "assets/images/icon/icon_detail_question_disabled.png";
import IconFavorites from "assets/images/icon/icon_detail_favorite.png";
import IconFavoritesActived from "assets/images/icon/icon_detail_favorite_actived.png";
import IconMap from "assets/images/icon/icon_detail_map.png";
import IconShare from "assets/images/icon/icon_detail_share.png";

interface Props {
  cul: CulturalAsset;
}

const DetailFooter = (props: Props) => {
  const [isFavorites, setIsFavorites] = useState(false);
  const navigate = useNavigate();

  const [questionCount, setQuestionCount] = useState<number>(0);

  useEffect(() => {
    Api.get(`/app/question/count/${props.cul.idx}`)
      .then((response) => response.data)
      .then(setQuestionCount);

    if (isUserLogin())
      Api.get(`app/favorites/check/${props.cul.idx}`).then((response) => setIsFavorites(response.data));
  }, [props.cul.idx]);

  const changeFavoriteHandler = () => {
    Api.get(`app/favorites/change/${props.cul.idx}`).then((response) => {
      if (response.data) {
        appSuccessMsg({
          msg: "즐겨찾기에 등록되었습니다.(즐겨찾기 된 문화재는 메인 화면 즐겨찾기 메뉴에서 확인이 가능합니다.)",
        });
      }
      setIsFavorites(response.data);
    });
  };

  const moveMap = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    NaverMapObject.instance?.morph(
      new naver.maps.LatLng(parseFloat(props.cul.latitude), parseFloat(props.cul.longitude)),
      18
    );
    navigate("/");
  };
  return (
    <Row className="m-0 border-bottom" id="detail-footer">
      <Row className="m-0 pt-2">
        <Col className="text-center" onClick={changeFavoriteHandler}>
          <div className="content-favorites text-center p-0" style={{ height: "30px" }}>
            <Image src={isFavorites ? IconFavoritesActived : IconFavorites} />
          </div>
          즐겨찾기
        </Col>
        <Col className="text-center" onClick={moveMap}>
          <div className="text-center p-0" style={{ height: "30px" }}>
            <Image src={IconMap} />
          </div>
          지도
        </Col>
        <Col className="text-center" onClick={() => appErrorMsg({ msg: "서비스 준비중입니다." })}>
          <div className="text-center p-0" style={{ height: "30px" }}>
            <Image src={IconShare} />
          </div>
          공유
        </Col>
        <Col className={`text-center ${questionCount > 0 ? "" : "disabled"}`}>
          <Link to={`/question/${props.cul.idx}`} id="question_count_wrap" className="text-decoration-none">
            <div className="text-center p-0" style={{ height: "30px" }}>
              {questionCount > 0 && <div id="question_count">{questionCount}</div>}
              <Image src={questionCount > 0 ? IconQuestion : IconQuestionDisabled} />
            </div>
            기출문제
          </Link>
        </Col>
      </Row>
    </Row>
  );
};

export default DetailFooter;
