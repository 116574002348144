import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import { getAddress, getMapCenter } from "../NaverMap";
import { BottomSheetContentList } from "./BottomSheetContentList";
import BottomSheetSearch from "./BottomSheetSearch";

export const BOTTOMSHEET_MODES = {
  HIDE: 0,
  MIDDLE: 1,
  TOP: 2,
};

const MIN_BOTTOMSHEET_HEIGHT = 260;
const MIN_Y = 110; // 바텀시트가 최대로 높이 올라갔을 때의 y 값

interface Props {
  wrapClass: string;
}
const BottomSheet = ({ wrapClass }: Props) => {
  const { bottomSheetItems, setBottomSheetItems, isOrder, orderBy, setOrderBy } = useContext(BottomSheetContext);
  const [mode, setMode] = useState(BOTTOMSHEET_MODES.HIDE);
  const [totalCnt, setTotalCnt] = useState(0);
  const [title, setTitle] = useState("");
  const [lastSearchCenter, setLastSearchCenter] = useState({ lng: 0, lat: 0 });
  const sheetRef = useBottomSheet(setMode);

  // TODO totalCnt가 2번 호출되는 문제 있음
  useEffect(() => {
    window.addEventListener("resize", windowResizeHandler);

    if (bottomSheetItems.length === 0) {
      setTotalCnt(0);
      setMode(BOTTOMSHEET_MODES.HIDE);
    } else {
      if (BottomSheetSearch.lastParam.pageNum === undefined || BottomSheetSearch.lastParam.pageNum === 0) {
        BottomSheetSearch.totalCnt().then(setTotalCnt);
      }
      if (mode === BOTTOMSHEET_MODES.HIDE) {
        setMode(BOTTOMSHEET_MODES.MIDDLE);
      }
    }

    return () => {
      window.removeEventListener("resize", windowResizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomSheetItems]);

  useEffect(() => {
    setBottomSheetItems(bottomSheetItems.slice(0, 20));
    if (!sheetRef.current) return;
    switch (mode) {
      case BOTTOMSHEET_MODES.HIDE:
        sheetAnimation(sheetRef.current, window.innerHeight);
        setBottomSheetItems([]);
        break;
      case BOTTOMSHEET_MODES.MIDDLE:
        sheetAnimation(sheetRef.current, window.innerHeight - MIN_BOTTOMSHEET_HEIGHT);
        getTitle();

        break;
      case BOTTOMSHEET_MODES.TOP:
        sheetAnimation(sheetRef.current, MIN_Y);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const windowResizeHandler = () => {
    if (!sheetRef.current) return;
    switch (mode) {
      case BOTTOMSHEET_MODES.HIDE:
        sheetAnimation(sheetRef.current, window.innerHeight, 0);
        break;
      case BOTTOMSHEET_MODES.MIDDLE:
        sheetAnimation(sheetRef.current, window.innerHeight - MIN_BOTTOMSHEET_HEIGHT, 0);

        break;
      case BOTTOMSHEET_MODES.TOP:
        sheetAnimation(sheetRef.current, MIN_Y, 0);
        break;
    }
  };

  const getTitle = () => {
    const center = getMapCenter();
    if (center.lat === lastSearchCenter.lat && center.lng === lastSearchCenter.lng) return;
    setLastSearchCenter(center);
    getAddress({
      lat: center.lat,
      lng: center.lng,
      callback: (status, response) => {
        if (status === naver.maps.Service.Status.OK) {
          setTitle(response.v2.address.jibunAddress);
        } else {
          setTitle("");
        }
      },
    });
  };

  const changeOrderBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    BottomSheetSearch.orderBy(value).then(setBottomSheetItems);
    setOrderBy(value);
  };

  return (
    <div ref={sheetRef} id="bottom_sheet" className={wrapClass} style={{ top: `${window.innerHeight}px` }}>
      <Row className="p-3 pb-0">
        <Col className="pt-1">
          <h5>{title}</h5>
        </Col>
      </Row>
      {isOrder && (
        <Row className="p-3 py-1 fs-7 text-brown-grey fw-r">
          <Col>
            <Form.Check
              inline
              name="search_type"
              label="거리순"
              type="radio"
              value={0}
              defaultChecked={orderBy === 0}
              onChange={changeOrderBy}
            />
            <Form.Check
              inline
              name="search_type"
              label="기출문제"
              type="radio"
              value={1}
              defaultChecked={orderBy === 1}
              onChange={changeOrderBy}
            />
          </Col>
        </Row>
      )}
      <BottomSheetContentList items={bottomSheetItems} totalCnt={totalCnt} mode={mode} />
    </div>
  );
};

const sheetAnimation = (sheet: HTMLDivElement, y: number, duration = 300) => {
  let animation = sheet.animate([{ top: `${y}px` }], {
    duration,
    easing: "ease-out",
    iterations: 1,
    fill: "both",
  });

  animation.onfinish = () => {
    sheet.style.setProperty("top", `${y}px`);
    sheet.classList.toggle("full", y === MIN_Y);
    animation.cancel();
  };
};

interface BottomSheetMetrics {
  touchStart: {
    sheetY: number;
    touchY: number;
  };
  touchMove: {
    prevTouchY: number;
    movingDirection: "none" | "down" | "up";
  };
  skip: boolean;
}

function useBottomSheet(setMode: Function) {
  const sheetRef = useRef<HTMLDivElement>(null);

  const metrics = useRef<BottomSheetMetrics>({
    touchStart: {
      sheetY: 0,
      touchY: 0,
    },
    touchMove: {
      prevTouchY: 0,
      movingDirection: "none",
    },
    skip: true,
  });

  useEffect(() => {
    const current = sheetRef.current;
    const handleTouchStart = (e: TouchEvent) => {
      metrics.current.skip = false;
      if (sheetRef.current?.style.getPropertyValue("top") === `${MIN_Y}px`) {
        metrics.current.skip = (e.target as Element).closest("#content_list_wrap") !== null;
      }

      const { touchStart, skip } = metrics.current;
      if (skip) return;

      touchStart.sheetY = current?.getBoundingClientRect().y as number;
      touchStart.touchY = e.touches[0].clientY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      const { touchStart, touchMove, skip } = metrics.current;
      if (skip) return;
      const currentTouch = e.touches[0];
      e.preventDefault();

      if (!touchMove.prevTouchY) touchMove.prevTouchY = touchStart.touchY;
      touchMove.movingDirection = touchMove.prevTouchY < currentTouch.clientY ? "down" : "up";

      const touchOffset = currentTouch.clientY - touchStart.touchY;
      let nextSheetY = touchStart.sheetY + touchOffset;

      if (nextSheetY <= MIN_Y) {
        nextSheetY = MIN_Y;
      }

      current?.style.setProperty("top", `${nextSheetY}px`);
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const { touchMove, skip } = metrics.current;
      let y = current?.getBoundingClientRect().y as number;

      if (skip) return;
      document.getElementById("content_list_wrap")?.scrollTo(0, 0);

      if (touchMove.movingDirection === "up") {
        y > getMaxY() ? setMode(BOTTOMSHEET_MODES.MIDDLE) : setMode(BOTTOMSHEET_MODES.TOP);
      } else if (touchMove.movingDirection === "down") {
        y < getMaxY() ? setMode(BOTTOMSHEET_MODES.MIDDLE) : setMode(BOTTOMSHEET_MODES.HIDE);
      }

      metrics.current = {
        touchStart: {
          sheetY: 0,
          touchY: 0,
        },
        touchMove: {
          prevTouchY: 0,
          movingDirection: "none",
        },
        skip: false,
      };
    };

    current?.addEventListener("touchstart", handleTouchStart);
    current?.addEventListener("touchmove", handleTouchMove);
    current?.addEventListener("touchend", handleTouchEnd);

    return () => {
      current?.removeEventListener("touchstart", handleTouchStart);
      current?.removeEventListener("touchmove", handleTouchMove);
      current?.removeEventListener("touchend", handleTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return sheetRef;
}

const getMaxY = () => {
  return window.innerHeight - MIN_BOTTOMSHEET_HEIGHT;
};
export default BottomSheet;
