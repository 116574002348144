import { appErrorMsg } from "assets/js/common";
import axios, { AxiosInstance } from "axios";

export const Api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // 기본 서버 주소 입력
  headers: {
    "Access-Control-Allow-Origin": "*",
    withCredentials: true,
  },
});

const getUserTokens = () => {
  let tokens = localStorage.getItem("user_tokens") || "";
  if (tokens) return JSON.parse(tokens).accessToken;
  return null;
};

// APP ACCESS TOKEN 갱신
const refreshToken = async () => {
  let tokens = localStorage.getItem("user_tokens") || "";
  if (!tokens) throw new Error("인증 정보 갱신에 실패하였습니다.");

  const response = await Api.get("app/auth/refresh", {
    headers: { Authorization: `Bearer ${JSON.parse(tokens).refreshToken}` },
  });
  let jsonTokens = JSON.parse(tokens);
  jsonTokens.accessToken = response.data;
  localStorage.setItem("user_tokens", JSON.stringify(jsonTokens));
  return response.data;
};

Api.interceptors.request.use((config) => {
  if (config.headers && config.headers["Authorization"]) return config;

  const token = getUserTokens();
  if (token && config.headers) config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (isNetworkErr(error)) {
      appErrorMsg({ msg: "서버에 접속할 수 없습니다." });
    } else if (isAuthErr(error)) {
      if (error.response.data.message === "U001") {
        localStorage.removeItem("user_tokens");
        window.location.reload();
      } else if (error.config.url !== "app/auth/refresh" && error.response.data.message === "T001") {
        const accessToken = await refreshToken();
        if (accessToken) {
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return Api.request(error.config);
        }
      }
    } else if (isErrorMessage(error)) {
      appErrorMsg({ msg: error.response.data.message });
    } else {
      return Promise.reject(error);
    }
    throw new Error("API 에러 발생");
  }
);

const isNetworkErr = (error: any) => {
  return error.response.status === 0 && error.code === "ERR_NETWORK";
};

const isAuthErr = (error: any) => {
  return error.response.status === 401;
};

const isErrorMessage = (error: any) => {
  return error.response.status === 400 && error.response?.data.code === "E001" && error.response.data.message;
};
