import { NaverMapObject } from "components/NaverMap";
import { initLastTitle } from "components/filters/FilterResult";
import { CulturalAsset } from "model/CulturalAsset";
import { Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface Props {
  cul: CulturalAsset;
}

const MENUS = [
  {
    title: "홈",
    link: "",
  },
  {
    title: "사진",
    link: "photo",
  },
  {
    title: "리뷰",
    link: "reviews",
  },
  {
    title: "이야기마당",
    link: "story",
  },
];

const DetailHeader = (props: Props) => {
  const location = useLocation();
  const path = location.pathname.split("/").pop();
  const navigate = useNavigate();

  const closeClickHandler = () => {
    initLastTitle();
    navigate("/");
    NaverMapObject.initOptions();
    NaverMapObject.search();
  };

  return (
    <Row className="m-0 border-bottom">
      <div className="w-100 overflow-auto">
        <div className="d-flex flex-row" id="app_detail_menu_wrap">
          {MENUS.map((menu) => (
            <Menu
              title={menu.title}
              link={`/detail/${props.cul.idx}/${menu.link}`}
              className={menu.link === path ? "active" : ""}
              key={menu.title}
            />
          ))}
        </div>
      </div>
      <div style={{ display: "contents" }} className="content-link-close" onClick={closeClickHandler}>
        <button type="button" className="btn-close btn-close-custom" aria-label="Close"></button>
      </div>
    </Row>
  );
};

const Menu = ({ title, link, className }: { title: string; link: string; className: string }) => {
  return (
    <div className={className}>
      <Link to={link} className="text-decoration-none">
        {title}
      </Link>
    </div>
  );
};

export default DetailHeader;
