import { APP_MODES, changeAppMode } from "features/appSlice";
import { Api } from "lib/axios/Api";
import { QuestionType } from "model/Question";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomSheetContext from "shared/contexts/BottomSheetContext";
import BottomSheetSearch from "../BottomSheet/BottomSheetSearch";
import { APP_MAP_SEARCH_MODES, getMapCenter, NaverMapObject } from "../NaverMap";

const QuestionFilter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [types, setTypes] = useState<QuestionType[]>([]);
  const { setBottomSheetItems, setIsOrder, orderBy } = useContext(BottomSheetContext);

  useEffect(() => {
    Api.get("/app/question_type/list").then((response) => {
      setTypes(response.data);
    });
  }, []);

  const clickHandler = (type: QuestionType) => {
    NaverMapObject.searchMode = APP_MAP_SEARCH_MODES.QUESTION;
    NaverMapObject.search({ typeIdx: type.idx }).then(() => {
      setIsOrder(true);
      BottomSheetSearch.items({ typeIdx: type.idx, ...getMapCenter(), orderBy }).then(setBottomSheetItems);
    });

    navigate("/search_result", { state: { title: `기출별 검색 > ${type.name}` } });
    dispatch(changeAppMode({ mode: APP_MODES.FILTER_SEARCH }));
  };

  return (
    <div style={{ width: "100vw", backgroundColor: "#FFF" }} id="app_region_filter">
      <Row xs={1}>
        <SelectCol defaultText="기출별" />
      </Row>
      <Row xs={3} sm={5} md={6} id="app_region_filter_items">
        {types.map((type, i) => (
          <Col className="p-3 border text-center" key={i} onClick={() => clickHandler(type)}>
            {type.name}
          </Col>
        ))}
      </Row>
    </div>
  );
};

const SelectCol = ({ text, defaultText }: any) => {
  return <Col className={`px-3 py-2 border text-center fw-bold text-rouge`}>{text ?? defaultText}</Col>;
};

export default QuestionFilter;
